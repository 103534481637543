import { injectable } from "inversify";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";
import PropertyRepository from "../../repositories/PropertyRepository";
import PropertyEntity from "../../entities/PropertyEntity";
import { Either, isLeft } from "fp-ts/lib/Either";
import ExceptionEntity from "../../entities/ExceptionEntity";
import di from "../../../dependencyInjection/DependencyInjection";

interface props {
    propertyRepository: PropertyRepository;
    preferencesProvider: PreferencesProvider;
}

@injectable()
class LikePropertyByIdUseCase {
    _propertyRepository: PropertyRepository;
    _preferencesProvider: PreferencesProvider;

    constructor(_: props) {
        this._propertyRepository = _.propertyRepository;
        this._preferencesProvider = _.preferencesProvider;
    }

    public call = async (property: PropertyEntity): Promise<Either<ExceptionEntity, void>> => {
        const response = await this._propertyRepository.likeProperty(property);
        if (isLeft(response)) return response;
        this._preferencesProvider.Actions.setLikedProperties([...this._preferencesProvider.Actions.likedProperties, property]);
        return response;
    }
}

export default LikePropertyByIdUseCase;

export const LikePropertyByIdUseCaseName = "LikePropertyByIdUseCase";