import MultimediaInterestPointEntity from "../../../domain/entities/MultimediaInterestPointEntity";

const fromJSON = (json: any): MultimediaInterestPointEntity => {
    return {
        positionX: json['position_x'],
        positionY: json['position_y'],
        interestPointName: json['name'],
    }
}

export default {
    fromJSON,
}