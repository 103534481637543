import { Either } from "fp-ts/lib/Either";
import PropertyRepository from "../../repositories/PropertyRepository";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { injectable } from "inversify";

interface props {
    propertyRepository: PropertyRepository;
}

@injectable()
class ApplyToRentPropertyUseCase {
    _propertyRepository: PropertyRepository;

    constructor(_: props) {
        this._propertyRepository = _.propertyRepository;
    }

    public call = async (params: {
        message: string, propertyId: string, rentSolicitudeFile: File,
        tenant: { name: string, lastname: string, email: string, dial: string, identification: string, phone: string, identificationFile: File, laboralLetterFile: File, paymentFile: File[], }
        coDebtor: { name: string, lastname: string, email: string, dial: string, identification: string, phone: string, identificationFile: File, laboralLetterFile: File, paymentFile: File[] }
    }): Promise<Either<ExceptionEntity, void>> => this._propertyRepository.applyToRentProperty(params);
}

export default ApplyToRentPropertyUseCase;

export const ApplyToRentPropertyUseCaseName = "ApplyToRentPropertyUseCase";