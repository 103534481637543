import di from "../../../../../dependencyInjection/DependencyInjection";
import PropertyEntity from "../../../../../domain/entities/PropertyEntity";
import PropertyDto from "../../../../dto/impl/PropertyDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

const GetPropertyByIdApiImpl = async (id: string, hasBeenSeen: boolean): Promise<PropertyEntity | undefined> => {
    //remove the fake api
    try{
        const idAsBase64 = btoa(id);
        const relativeUrl = "/getPropertyWithData/" + idAsBase64 + "?firstime=" + hasBeenSeen;
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        if(!response) return undefined;
        const property = PropertyDto.fromJSON(response);
        return property;
    }catch (e) {
        return undefined;
    }
}

export default GetPropertyByIdApiImpl;