import "./HeaderPropertyComponentStyles.scss";
import { FC, useContext, useState } from "react";
import HeaderPropertyComponentProps from "./HeaderPropertyComponentProps";
import KeyWordLocalization from "../../../../../providers/localization/KeyWordLocalization";
import LocalizationContext from "../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import CapitalizeFirstLetter from "../../../../tools/capitalizeFirstLetter/capitalizeFirstLetter";
import PropertyEntity from "../../../../../../domain/entities/PropertyEntity";

const HeaderPropertyComponent: FC<HeaderPropertyComponentProps> = ({ property }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [liked, setLiked] = useState(property.liked);

    const getPropertyTitle = (property: PropertyEntity) => {

        let business: String = "";
        const businessNames: String[] = property.businesses!.map(business => CapitalizeFirstLetter(i18n(business.typeOfBusiness.name)));
        if (businessNames?.length === 1) {
            business = businessNames[0];
        } else {
            const lastIndex: number = businessNames.length - 1;
            business = businessNames?.slice(0, lastIndex).join(", ") + " | " + businessNames?.[lastIndex];
        }
        return `${CapitalizeFirstLetter(i18n(property.typeProperty!.name))} ${i18n(KeyWordLocalization.HeaderPropertyComponentJoinTitleWord)} ${business}, ${CapitalizeFirstLetter(i18n(property.location!.neighborhood.zone!.name))}, ${property.location?.neighborhood.name} ${i18n(KeyWordLocalization.HeaderPropertyComponentJoinCityWord)} ${CapitalizeFirstLetter(property.location!.neighborhood.zone!.city!.name)}`;
    }
    return <div className="header_property_component my-4">
        <div className="row">
            <div className="col-12 d-flex justif-content-between">
                <div className="flex-grow-1">
                    <h1 className="header_property_component__title">
                        {getPropertyTitle(property)}
                    </h1>

                </div>
            </div>
        </div>
        <div className="d-flex justify-content-between">
            {property.tag && <div>
                <div className="tag_container" style={{ backgroundColor: property.tag.color }}>
                    <span className="tag">{i18n(property.tag.name)}</span>
                </div>
            </div>}
            <div className="line_icons ms-2 justify-content-end">
                <div className="line_icon" style={{ boxShadow: 'none' }}>
                    <span className="material-symbols-outlined">
                        visibility
                    </span>
                    <span className="hidden_text">{property.seenCount} {i18n(KeyWordLocalization.HeaderPropertyComponentVisits)}</span>
                </div>
            </div>
        </div>
    </div >
}

export default HeaderPropertyComponent;