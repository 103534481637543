import "./FavoritesPageStyles.scss";
import { FC, useContext, useEffect, useState } from "react";
import PreferencesContext from "../../../domain/providers/preferences/PreferencesContext";
import PreferencesContextType from "../../../domain/providers/preferences/PreferencesContextType";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../providers/localization/KeyWordLocalization";
import IconsSVG from "../../components/icons/IconsSVG";
import { Link } from "react-router-dom";
import { routes } from "../../routes/Routes";
import CardPropertyComponent from "../../components/cardProperty/CardPropertyComponent";
import FavoritesPageMetaTagComponent from "../../seo/metas/FavoritesPageMetaTagComponent";
import di from "../../../dependencyInjection/DependencyInjection";
import GetLikedPropertiesUseCase, { GetLikedPropertiesUseCaseName } from "../../../domain/use_cases/properties/GetLikedPropertiesUseCase";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

const FavoritesPage: FC<{}> = () => {
    const { likedProperties } = useContext(PreferencesContext) as PreferencesContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [loading, setLoading] = useState<boolean>(true);

    const _load = async () => {
        setLoading(true);
        await di.get<GetLikedPropertiesUseCase>(GetLikedPropertiesUseCaseName).call();
        setLoading(false);
    }

    useEffect(() => {
        _load();
    }, []);

    if (loading) return <>
        <FavoritesPageMetaTagComponent />
        <div className="container">
            <div className="p-5">
                <LoadingComponent showLogo />
            </div>
        </div>
    </>
    else if (likedProperties.length == 0)
        return <>
            <FavoritesPageMetaTagComponent />
            <div className="favorites_page no_content">
                <div className="w-100 title_container d-none">
                    <div className="container titles">
                        <h1>{i18n(KeyWordLocalization.FavoritesPageTitle)}</h1>
                        <h2>{i18n(KeyWordLocalization.FavoritesPageLAC)}</h2>
                    </div>
                </div>
                <div className="w-100 bg_light">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-md-6"><img src="/assets/images/liked_properties.png" className='img-fluid' alt="" /></div>
                            <div className="col-md-6 d-flex flex-column justify-content-center">
                                <span className=" mini_title">
                                    {i18n(KeyWordLocalization.FavoritesPageNoFavoritesMiniTitle)}
                                </span>
                                <div className="w-100">
                                    <p className="text_yellow text_bold  title">
                                        {i18n(KeyWordLocalization.FavoritesPageNoFavoritesTitle)}
                                    </p>

                                </div>
                                <span className=" description">
                                    {i18n(KeyWordLocalization.FavoritesPageHowAddFavorites)}
                                    <IconsSVG.FavoriteFilled color="red" />
                                </span>
                                <div className="w-100 d-flex justify-content-center justify-content-start mt-3">
                                    <Link to={routes().properties.relativePath} className="mbtn btn_orange btn_properties" style={{ color: 'white' }}>
                                        {i18n(KeyWordLocalization.FavoritesPageSeeProperties)}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    return <>
        <FavoritesPageMetaTagComponent />
        <div className="favorites_page yes_added">
            <div className="w-100 bg_light">
                <div className="container py-3">
                    <h1 className="h5">{i18n(KeyWordLocalization.FavoritesPageTitle)}</h1>
                    <h2 className="h6">{i18n(KeyWordLocalization.FavoritesPageLAC)}</h2>

                </div>
            </div>
            <div className="container">
                <div className="row">
                    {likedProperties.map((property, index) => <div className="col-md-6 col-lg-4 col-xl-3 my-3" key={index}>
                        <CardPropertyComponent property={property} />
                    </div>)}
                </div>
            </div>
        </div>
    </>
}

export default FavoritesPage;