import './ShareLinkModalComponentStyles.scss';
import { FC, useContext } from "react";
import ShareLinkModalComponentProps from "./ShareLinkModalComponentProps";
import {
    EmailShareButton,
    FacebookShareButton,
    LineShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,

    EmailIcon,
    FacebookIcon,
    LineIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    XIcon,
} from "react-share";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/KeyWordLocalization';

const ShareLinkModalComponent: FC<ShareLinkModalComponentProps> = ({ link }) => {
    const { closeModalCustom, addToast } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const _copyOnClipboard = () => {
        navigator.clipboard.writeText(link);
        addToast('Enlace copiado al portapapeles', 'success', undefined);
        closeModalCustom();
    }

    return <div className="share_link_modal_component">
        <div className="row">
            <div className="col-6 col-md-4 col-lg-3 my-3 d-flex justify-content-center align-items-center d-flex justify-content-center align-items-center">
                <WhatsappShareButton url={link}  onClick={closeModalCustom}>
                    <div className="d-flex flex-column align-items-center">
                        <WhatsappIcon size={32} round />
                        {i18n(KeyWordLocalization.ShareLinkModalComponentWhatsapp)}
                    </div>
                </WhatsappShareButton>
            </div>
            <div className="col-6 col-md-4 col-lg-3 my-3 d-flex justify-content-center align-items-center">
                <FacebookShareButton url={link} onClick={closeModalCustom}>
                    <div className="d-flex flex-column align-items-center">
                        <FacebookIcon size={32} round />
                        {i18n(KeyWordLocalization.ShareLinkModalComponentFacebook)}
                    </div>
                </FacebookShareButton>
            </div >
            <div className="col-6 col-md-4 col-lg-3 my-3 d-flex justify-content-center align-items-center">
                <TwitterShareButton url={link} onClick={closeModalCustom}>
                    <div className="d-flex flex-column align-items-center">
                        <XIcon size={32} round />
                        {i18n(KeyWordLocalization.ShareLinkModalComponentX)}
                    </div>
                </TwitterShareButton>
            </div >
            <div className="col-6 col-md-4 col-lg-3 my-3 d-flex justify-content-center align-items-center">
                <TelegramShareButton url={link} onClick={closeModalCustom}>
                    <div className="d-flex flex-column align-items-center">
                        <TelegramIcon size={32} round />
                        {i18n(KeyWordLocalization.ShareLinkModalComponentTelegram)}
                    </div>
                </TelegramShareButton>
            </div >
            <div className="col-6 col-md-4 col-lg-3 my-3 d-flex justify-content-center align-items-center">
                <div className="" onClick={_copyOnClipboard}>
                    <div className="d-flex flex-column align-items-center">
                        <div className="copy_clipboard">
                        <span className="material-symbols-outlined">
                            content_copy
                        </span>
                        </div>
                        {i18n(KeyWordLocalization.ShareLinkModalComponentCopyClipboard)}
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 my-3 d-flex justify-content-center align-items-center">
                <EmailShareButton url={link} onClick={closeModalCustom}>
                    <div className="d-flex flex-column align-items-center">
                        <EmailIcon size={32} round />
                        {i18n(KeyWordLocalization.ShareLinkModalComponentEmail)}
                    </div>
                </EmailShareButton >
            </div >
            <div className="col-6 col-md-4 col-lg-3 my-3 d-flex justify-content-center align-items-center">
                <LineShareButton url={link} onClick={closeModalCustom}>
                    <div className="d-flex flex-column align-items-center">
                        <LineIcon size={32} round />
                        {i18n(KeyWordLocalization.ShareLinkModalComponentLine)}
                    </div>
                </LineShareButton>
            </div >
            <div className="col-6 col-md-4 col-lg-3 my-3 d-flex justify-content-center align-items-center">
                <LinkedinShareButton url={link} onClick={closeModalCustom}>
                    <div className="d-flex flex-column align-items-center">
                        <LinkedinIcon size={32} round />
                        {i18n(KeyWordLocalization.ShareLinkModalComponentLinkedIn)}
                    </div>
                </LinkedinShareButton>
            </div >

        </div >
    </div >
}

export default ShareLinkModalComponent;