import { Either, right, left } from "fp-ts/lib/Either";
import di from "../../../../../dependencyInjection/DependencyInjection";
import PropertyEntity from "../../../../../domain/entities/PropertyEntity";
import PropertyDto from "../../../../dto/impl/PropertyDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";
import ExceptionEntity, { ExceptionEntityFromError } from "../../../../../domain/entities/ExceptionEntity";

//TODO add liked on back
//TODO remove the ? operator on mostLiked
const GetOustandedAndRecentPropertiesApiImpl = async (): Promise<Either<ExceptionEntity, { outstanded: PropertyEntity[]; recent: PropertyEntity[]; mostLiked: PropertyEntity[] }>> => {
    try {
        const relativeUrl = "/getOutstandingAndLastest";
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        const parsed = {
            outstanded: response.outstandings.map((property: any) => PropertyDto.fromJSON(property)),
            recent: response.lastests.map((property: any) => PropertyDto.fromJSON(property)),
            mostLiked: response.mostLiked?.map((property: any) => PropertyDto.fromJSON(property)) ?? [],
        }
        return right(parsed);
    } catch (e) {
        return left(ExceptionEntityFromError(e));
    }
}

export default GetOustandedAndRecentPropertiesApiImpl;