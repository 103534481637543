import { FC, useContext } from "react";
import { Link, useParams } from 'react-router-dom';
import { routes } from '../../../../routes/Routes';
import KeyWordLocalization from "../../../../providers/localization/KeyWordLocalization";
import LocalizationContext from "../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";

const FooterLinksComponent: FC<{}> = () => {
    const { businesses, city } = useParams();
    const {i18n, localization} = useContext(LocalizationContext) as LocalizationContextType;

    const selling = businesses ? businesses.replaceAll('for-', '').split('-').length > 1 || businesses.includes('venta') || businesses.includes('sell') : true;
    const renting = businesses ? businesses.replaceAll('for-', '').split('-').length > 1 || businesses.includes('alquiler') || businesses.includes('rent') : true;
    const onlyCali = city ? city == 'cali' : false;
    const onlyJamundi = city == "jamundi";

    const links = [
        {
            data: {
                type: KeyWordLocalization.house,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            priority: 1,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.apartment,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            link: `${routes().properties.relativePath}/venta/apartamento/cali`,
            priority: 1,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.studioApartment,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            link: `${routes().properties.relativePath}/venta/apartaestudio/cali`,
            priority: 3,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.warehouse,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            priority: 4,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.countryHouse,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            priority: 5,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.farm,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            priority: 3,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.local,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            priority: 4,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.lot,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            priority: 3,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.office,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            priority: 4,
            condition: onlyCali || (selling && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.farm,
                business: KeyWordLocalization.sell,
                city: "Cali"
            },
            priority: 1,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.apartment,
                business: KeyWordLocalization.rent,
                city: "Cali"
            },
            priority: 1,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.studioApartment,
                business: KeyWordLocalization.rent,
                city: "Cali"
            },
            priority: 2,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.warehouse,
                business: KeyWordLocalization.rent,
                city: "Cali"
            },
            priority: 4,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.countryHouse,
                business: KeyWordLocalization.rent,
                city: "Cali"
            },
            priority: 4,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.farm,
                business: KeyWordLocalization.rent,
                city: "Cali"
            },
            priority: 3,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.local,
                business: KeyWordLocalization.rent,
                city: "Cali"
            },
            priority: 4,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.lot,
                business: KeyWordLocalization.rent,
                city: "Cali"
            },
            priority: 3,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.office,
                business: KeyWordLocalization.rent,
                city: "Cali"
            },
            priority: 4,
            condition: onlyCali || (renting && !onlyJamundi)
        },
        {
            data: {
                type: KeyWordLocalization.farm,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 1,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.apartment,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 1,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.studioApartment,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 3,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.warehouse,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 4,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.countryHouse,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 5,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.farm,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 3,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.local,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 4,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.lot,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 3,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.office,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 4,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.farm,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 6,
            condition: onlyJamundi || (selling && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.house,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 1,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.apartment,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 1,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.studioApartment,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 1,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.warehouse,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 4,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.countryHouse,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 4,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.farm,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 3,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.local,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 4,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.lot,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 3,
            condition: onlyJamundi || (renting && !onlyCali)
        },
        {
            data: {
                type: KeyWordLocalization.office,
                business: KeyWordLocalization.rent,
                city: "jamundi"
            },
            priority: 4,
            condition: onlyJamundi || (renting && !onlyCali)
        },
    ];

    const getLink = (propertyType: string, business: string, city: string) => {
        if(localization.code == "es") return `${routes().properties.relativePath}/${i18n(business)}/${i18n(propertyType)}/${city}`;
        return `${routes().properties.relativePath}/for_${business}/${propertyType}/${city}`;
    }

    const _getName = (propertyType: string, business: string, city: string) => {
        const propertyTS = i18n(propertyType);
        const businessTS = i18n(business);
        return i18n(KeyWordLocalization.FooterLinkComponentRow, { type: propertyTS, business: businessTS, city: i18n(city) });
    }

    const sortBy = (a: any, b: any): number => {
        if (a.priority != b.priority) {
            return a.priority - b.priority
        }
        else return (_getName(a.data.type, a.data.business, a.data.city)).localeCompare((_getName(b.data.type, b.data.business, b.data.city)));
    }

    return <div className="container py-5 suggest_box">
        <div className="row">
            <div className="mb-3">
                <h2 className="w-100 text-center text_color_main h5">{i18n(KeyWordLocalization.FooterLinkComponentTitle)}</h2>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3  text-center text-sm-start">
                {links.filter(l => l.condition).sort((a, b) => sortBy(a, b)).slice(0, 5).map((l, i) => <div key={i} >
                    <h4 className='text_ultra_small'>
                        <Link to={getLink(l.data.type, l.data.business, l.data.city)} className="text-capitalize">{_getName(l.data.type, l.data.business, l.data.city)}</Link>
                    </h4>
                </div>)}
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-none d-sm-block">
                {links.filter(l => l.condition).sort((a, b) => sortBy(a, b)).slice(5, 10).map((l, i) => <div key={i}>
                    <h4 className='text_ultra_small'>
                        <Link to={getLink(l.data.type, l.data.business, l.data.city)} className="text-capitalize">{_getName(l.data.type, l.data.business, l.data.city)}</Link>
                    </h4>
                </div>)}
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-none d-md-block">
                {links.filter(l => l.condition).sort((a, b) => sortBy(a, b)).slice(10, 15).map((l, i) => <div key={i}>
                    <h4 className='text_ultra_small'>
                        <Link to={getLink(l.data.type, l.data.business, l.data.city)} className="text-capitalize">{_getName(l.data.type, l.data.business, l.data.city)}</Link>
                    </h4>
                </div>)}
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-none d-lg-block">
                {links.filter(l => l.condition).sort((a, b) => sortBy(a, b)).slice(15, 20).map((l, i) => <div key={i}>
                    <h4 className='text_ultra_small'>
                        <Link to={getLink(l.data.type, l.data.business, l.data.city)} className="text-capitalize">{_getName(l.data.type, l.data.business, l.data.city)}</Link>
                    </h4>
                </div>)}
            </div>
        </div>
    </div>
}

export default FooterLinksComponent;