import './RangePickerComponentStyles.scss';
import { FC, useContext, useEffect } from "react";
import RangePickerComponentProps from "./RangePickerComponentProps";
import { RangeSlider } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import { ErrorMessage } from "@hookform/error-message";
import Validators from '../../../utils/Validators';
import CurrencyParse from '../../../utils/CurrencyParse';
import KeyWordLocalization from '../../../providers/localization/KeyWordLocalization';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';

const RangePickerComponent: FC<RangePickerComponentProps> = ({ formFunctions, keyName, onChange, min, max, showM2, label, isCurrency = false }) => {
    const { register, formState: { errors }, setValue, watch } = formFunctions;
    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    const minValue: number | undefined = watch(`${keyName}.min`);
    const maxValue: number | undefined = watch(`${keyName}.max`);

    const _handleChange = (value: number[]) => {
        setValue(`${keyName}.min`, value[0]);
        setValue(`${keyName}.max`, value[1]);
        onChange?.(value);
    }

    return <div className="range_picker_component my-3">
        {label && <label className='mb-3'>{label}{showM2 && <> m&sup2;</>}</label>}
        <div className="mx-2">
            <RangeSlider
                value={[minValue ?? min, maxValue ?? max]}
                renderTooltip={(value) => {
                    let parsed = ""
                    if(value === undefined) return "";
                    if(isCurrency) parsed = CurrencyParse.toCop(value ?? 0);
                    else parsed = value!.toString();
                    if(value! >= max) return `${parsed}+`
                    else return parsed;
                }}
                min={min} max={max} onChange={_handleChange} />
        </div>
        <div className="div d-flex justify-content-between my-2">
            <div className="flex-grow-1 me-2">
                <input type="text"
                    value={minValue ? (isCurrency ? CurrencyParse.toCop(minValue) : minValue) : ""}
                    min={min} max={maxValue ?? max}
                    className="form-control" placeholder={i18n(KeyWordLocalization.RangePickerComponentMinimum)} {...register(`_${keyName}.min`, Validators({
                        onChange: (val: any) => setValue(`${keyName}.min`, val),
                        price: isCurrency,
                        minValue: min,
                        maxValue: maxValue ?? max
                    }))} />
            </div>
            <div className="flex-grow-1 ms-2">
                <input type="text" value={maxValue ? (isCurrency ? CurrencyParse.toCop(maxValue) : maxValue) : ""} min={minValue ?? min} max={max} className="form-control" placeholder={i18n(KeyWordLocalization.RangePickerComponentMaximum)} {...register(`_${keyName}.max`, Validators({
                    onChange: (val: any) => setValue(`${keyName}.max`, val),
                    maxValue: max,
                    price: isCurrency,
                    minValue: minValue ?? min
                }))} />
            </div>
        </div>
        <ErrorMessage errors={errors} name={`${keyName}.min`} as="aside" />
        <ErrorMessage errors={errors} name={`${keyName}.max`} as="aside" />
    </div>
}

export default RangePickerComponent;