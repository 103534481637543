import './CardPropertyComponentStyles.scss';
import { FC, useContext } from 'react';
import CardPropertyComponentProps from './CardPropertyComponentProps';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import CurrencyParse from '../../utils/CurrencyParse';
import FriendlyUrls from '../../seo/FriendlyUrls';
import CardItem from './subcomponents/CardItem';
import KeyWordLocalization from '../../providers/localization/KeyWordLocalization';
import StringUtils from '../../utils/StringUtils';
import { Link } from 'react-router-dom';
import IconsSVG from '../icons/IconsSVG';

const CardPropertyComponent: FC<CardPropertyComponentProps> = ({ property, seeAsGrid = true, noRelevant = false, }) => {

    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const H2Tag: FC<any> = ({ children, style, className }) => !noRelevant ? <h2 className={'property_title ' + className} style={style}>{children}</h2> : <h3 className={'property_title ' + className} style={style}>{children}</h3>;
    const H3Tag: FC<any> = ({ children, style, className }) => !noRelevant ? <h3 className={'h6 ' + className} style={style}>{children}</h3> : <h4 className={'h6 ' + className} style={style}>{children}</h4>;


    if (property == null) {
        return <div className={`card_property_component m-0 mt-3 ${(seeAsGrid ? "grid" : "list")}`}>
            <div className="data_container">
                <div className="d-flex row align-items-stretch">
                    <div className={`container_img ${seeAsGrid ? "col-12" : "col-12 col-md-4"}`}>
                        <div className="img_preview_container">
                            <div className='img_preview loader_preview' />
                        </div>
                    </div>
                    <div className={`flex-grow-1 d-flex flex-column pt-4 ps-4 pe-4 ${seeAsGrid ? "col-12 flex-grow-1" : "col-md-8"}`}>
                        <div className="loader_preview" style={{ height: '2.5em' }}></div>
                        {!seeAsGrid && <div className="loader_preview" style={{ height: '2.1em' }}></div>}
                        {!seeAsGrid && <div className="loader_preview my-3" style={{ height: '4.1em' }}></div>}
                        <div className="col-12">
                            <div className="row d-flex justify-content-between mt-2">
                                <div className="col-12 col-lg-5">
                                    <div className="loader_preview mt-3" style={{ height: '2.3em' }}></div>
                                </div>
                                <div className="col-12 col-lg-5">
                                    <div className="loader_preview mt-3" style={{ height: '2.3em' }}></div>
                                </div>
                            </div>
                        </div>
                        <div className={`row pb-4 d-flex justify-content-between mt-2 ${seeAsGrid ? "" : "me-4"}`}>
                            <div className="col-4">
                                <div className="loader_preview my-3" style={{ height: '3.2em' }}></div>
                            </div>
                            <div className="col-4">
                                <div className="loader_preview my-3" style={{ height: '3.2em' }}></div>
                            </div>
                            <div className="col-4">
                                <div className="loader_preview my-3" style={{ height: '3.2em' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    }
    return <Link to={FriendlyUrls.getUrlProperty(property)} target='_blank' className={`mb-4 px-3 card_property_component ${(seeAsGrid ? "col-12 col-sm-6 col-md-6 col-lg-4 grid" : "col-12 list")}`}>
        <div className={`data_container ${property.tag?.color && 'has_tag'}`} style={{ borderColor: property.tag?.color }}>
            <div className="d-flex row align-items-stretch">
                <div className={`container_img ${seeAsGrid ? "col-12" : "col-md-4"}`}>
                    {property.tag && <div className="tag_property" style={{ backgroundColor: property.tag.color }}>{i18n(property.tag.name)}</div>}
                    <div className="like_icon">
                        {property.liked && <IconsSVG.FavoriteFilled color="red" />}
                    </div>
                    <div className="img_preview_container">
                        <img src={property.previewImage} className='img_preview' alt={FriendlyUrls.getShortDescriptionOfProperty(property)} title={FriendlyUrls.getShortDescriptionOfProperty(property)} />
                    </div>
                </div>
                <div className={`flex-grow-1 d-flex flex-column pt-4 ps-4 pe-4 ${seeAsGrid ? "col-12 flex-grow-1" : "col-md-8"}`}>
                    <H2Tag>{FriendlyUrls.getPropertyTitle(property)}</H2Tag>
                    {!seeAsGrid && <H3Tag className="h6"><strong>{StringUtils.makeFirstLetterUpperCase(property.name)}</strong></H3Tag>}
                    {!seeAsGrid && <div className="flex-grow-1 text_description">
                        <H3Tag className='h6'>{StringUtils.makeFirstLetterUpperCase(property.description)}</H3Tag>...
                    </div>}
                    <div className="col-12">
                        <div className="row">
                            {property.businesses?.map((business, index) => <div key={index} className={property.businesses!.length == 1 ? 'col-12' : 'col-xl-6'}>
                                <H3Tag className={`h6 text_bold business_container ${property.businesses!.length == 1 && 'alone'}`} style={{ color: business.typeOfBusiness.color }}>
                                    <div className="type_business">{i18n(KeyWordLocalization.SEOPrice, {
                                        business: i18n(business.typeOfBusiness.name),
                                    })}</div>
                                    <div className='price_container'><strong>{CurrencyParse.toCop(business.price)}</strong> <span className='text_small_2'>COP</span></div>
                                </H3Tag>
                            </div>)}
                        </div>
                    </div>
                    <div className={`pb-4 d-flex justify-content-between mt-2 ${seeAsGrid ? "" : "me-4"}`}>
                        <CardItem name={i18n("CardPropertyComponentArea")} value={`${property.area?.toString()!}m²`} />
                        <CardItem name={i18n("CardPropertyComponentRooms")} value={property.rooms?.toString()!} />
                        <CardItem name={i18n("CardPropertyComponentRestrooms")} value={property.restrooms?.toString()!} />
                        {seeAsGrid ? null : <CardItem name={i18n("CardPropertyComponentFloors")} value={property.floors?.toString()!} />}
                    </div>
                </div>
            </div>
        </div>
    </Link>
}

export default CardPropertyComponent;