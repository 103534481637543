/********************************************************************************
 * File Header - Constants
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: March 14, 2024
 *
 * Description:
 * This is the file is irrelevant, it is only used to complete the branch test, and avoid errors
 * when the app tries to inject the constants
 ********************************************************************************/

import Constants from "./Constants";
import ConstantsDev from "./ConstantsDev";

const ConstantsFake: Constants = ConstantsDev;

export default ConstantsFake;