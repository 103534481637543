import GetLikedPropertiesApiImpl from "./GetLikedPropertiesApiImpl";

const IsPropertyLikedApiImpl = async (propertyId: string, keySave: string): Promise<boolean> => {
    try {
        const listPropertiies = await GetLikedPropertiesApiImpl(keySave);
        return listPropertiies.some((property) => property.id === propertyId);
    }
    catch {
        return false;
    }
}

export default IsPropertyLikedApiImpl;