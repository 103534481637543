import { FC } from "react";
import './ContactInformationComponentStyles.scss';
import ContactInformationComponentProps from "./ContactInformationComponentProps";

const ContactInformationComponent: FC<ContactInformationComponentProps> = ({icon, title, child}) => {

    return <div className="contact_card_component d-flex flex-column mb-3">
            <div className="d-flex justify-content-start card-title align-items-center">
                {icon}
                <h2>{title}</h2>
            </div>
            <div className="bg_light card-body">
                {child}
            </div>
        </div>
}

export default ContactInformationComponent;