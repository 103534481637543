import './ContactUsPageStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from '../../providers/localization/KeyWordLocalization';
import ContactInformationComponent from './components/contactInformation/ContactInformationComponent';
import PersonEntity from '../../../domain/entities/PersonEntity';
import ContactPersonItemComponent from '../properties/detailed/components/contactProperty/contactPersonItem/ContactPersonItemComponent';
import PhoneInput, { Country, formatPhoneNumberIntl } from 'react-phone-number-input'
import { useForm } from 'react-hook-form';
import ContactInfoEntity from '../../../domain/entities/ContactInfoEntity';
import di from '../../../dependencyInjection/DependencyInjection';
import PostContactUseCase, { PostContactUseCaseName } from '../../../domain/use_cases/contact/PostContactUseCase';
import Validators from '../../utils/Validators';
import { ErrorMessage } from '@hookform/error-message';
import ContactUsPageMetaTagComponent from '../../seo/metas/ContactUsPageMetaTagComponent';
import SendFormButtonComponent from '../../components/contactFormButton/SendFormButtonComponent';
import { ButtonType } from '../../components/contactFormButton/SendFormButtonComponentProps';
import { isLeft } from 'fp-ts/lib/Either';
import GetContactInfoPreferencesUseCase, { GetContactInfoPreferencesUseCaseName } from '../../../domain/use_cases/contact/GetContactInfoPreferencesUseCase';
import ModalsContext from '../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../domain/providers/modal/ModalsContextType';

const marlonData: PersonEntity = {
    fullname: 'Marlon Méndez',
    cellphone: '316 288 5158',
    email: 'marlon.mendez@lac.com.co',
}

const adrianaData: { person: PersonEntity, cellphone2: string } = {
    person: {
        fullname: 'Adriana Castañeda',
        cellphone: '316 288 5158',
        email: 'asesorias@lac.com.co',
    },
    cellphone2: '312 295 8733',
}

const ContactUsPage: FC<{}> = () => {

    const { openModalCustom } = useContext(ModalsContext) as ModalsContextType;

    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const getDefaultCountry = Intl.DateTimeFormat().resolvedOptions().timeZone == "America/Bogota" ? "CO" : undefined;

    const { i18n, localization } = useContext(LocalizationContext) as LocalizationContextType;

    const _handleSubmit = async (data: any) => {
        if (loading) return;

        setLoading(true);

        const dividePhone = () => {
            const phoneWithDial = getValues(KeyWordLocalization.ContactUsPagePhone);
            const phoneSplittedd = formatPhoneNumberIntl(phoneWithDial).split(' ');
            const dial = phoneSplittedd[0];
            const phone = phoneSplittedd.slice(1).join('');
            return { dial, phone };
        }
        const { dial, phone } = dividePhone();
        const contactInfo: ContactInfoEntity = {
            email: data[KeyWordLocalization.ContactUsPageEmail],
            name: data[KeyWordLocalization.ContactUsPageName],
            lastname: data[KeyWordLocalization.ContactUsPageLastName],
            phone: phone,
            dial: dial,
            userContent: data[KeyWordLocalization.ContactUsPageMessage]
        }
        const response = await di.get<PostContactUseCase>(PostContactUseCaseName).call(
            contactInfo,
            data[KeyWordLocalization.ContactUsPageSuscribe],
            data[KeyWordLocalization.ContactUsPageSavePreferences],
        );

        setLoading(false);

        if (isLeft(response)) {
            openModalCustom("sm", i18n(KeyWordLocalization.FinishModalErrorTitle), i18n(KeyWordLocalization.FinishModalErrorBody), {
                closeButton: true,
            });
        } else {
            openModalCustom("sm", i18n(KeyWordLocalization.FinishModalSuccessTitle), i18n(KeyWordLocalization.FinishModalSuccessBody), {
                closeButton: true,
            });
        }


    }

    const _loadData = async () => {
        const defaultValues: ContactInfoEntity | undefined = await di.get<GetContactInfoPreferencesUseCase>(GetContactInfoPreferencesUseCaseName).call();
        if (defaultValues == undefined) return;
        setValue(KeyWordLocalization.ContactUsPageName, defaultValues.name);
        setValue(KeyWordLocalization.ContactUsPageLastName, defaultValues.lastname);
        setValue(KeyWordLocalization.ContactUsPageEmail, defaultValues.email);
        setValue(KeyWordLocalization.ContactUsPagePhone, defaultValues.dial + defaultValues.phone);
        setValue(KeyWordLocalization.ContactUsPageSavePreferences, true);
    }

    useEffect(() => {
        _loadData();
    }, []);

    return <>
        <ContactUsPageMetaTagComponent />
        <div className="contact_us_page">
            <div className="w-100 bg_light py-3">
                <div className="container">
                    <h1 className="title_h1">{i18n(KeyWordLocalization.ContactUsPageTitle)}</h1>
                    <h2 className='mini_title_h2'>{i18n(KeyWordLocalization.TRADE_MARK)}</h2>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-md-between justify-content-lg-around">
                    <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
                        <h2 className='my-4 contact_us_title'>{i18n(KeyWordLocalization.ContactUsPageTitle2)}</h2>
                        <div>{i18n(KeyWordLocalization.ContactUsPageDescription)}</div>
                        <form onSubmit={handleSubmit(_handleSubmit)}>
                            <div className="row my-4 align-items-center">
                                <div className="row mx-0 px-0 mb-3">
                                    <div className="col-12 col-sm-6">
                                        <div className={`form-group ${errors[KeyWordLocalization.ContactUsPageName] && 'error'}`}>
                                            <label className='form-label'>{i18n(KeyWordLocalization.ContactUsPageName)}</label>
                                            <input type="text" className="form-control" {...register(KeyWordLocalization.ContactUsPageName, Validators({
                                                required: true,
                                            }))} />
                                            <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.ContactUsPageName} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className={`form-group ${errors[KeyWordLocalization.ContactUsPageLastName] && 'error'}`}>
                                            <label className='form-label'>{i18n(KeyWordLocalization.ContactUsPageLastName)}</label>
                                            <input type="text" className="form-control" {...register(KeyWordLocalization.ContactUsPageLastName, Validators({
                                                required: true,
                                            }))} />
                                            <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.ContactUsPageLastName} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0 px-0 mb-3">
                                    <div className="col-6 col-sm-6">
                                        <div className={`form-group ${errors[KeyWordLocalization.ContactUsPagePhone] && 'error error_group'}`}>
                                            <label className='form-label'>{i18n(KeyWordLocalization.ContactUsPagePhone)}</label>
                                            <PhoneInput
                                                defaultCountry={getDefaultCountry}
                                                onChange={phone => setValue(KeyWordLocalization.ContactUsPagePhone, phone)}
                                                className="form-control"
                                                value={watch(KeyWordLocalization.ContactUsPagePhone)} />
                                            <input type="hidden" className="form-control" {...register(KeyWordLocalization.ContactUsPagePhone, Validators({
                                                required: true,
                                                minLength: 10,
                                                maxLength: 15,
                                                //pattern prefix more phone example +1 123456789
                                                pattern: {
                                                    value: RegExp(/^[+][0-9]{1,3}[0-9]{10,14}$/),
                                                    message: i18n(KeyWordLocalization.ContactFormPropertyComponentPhoneNoIndex)
                                                }
                                            }))} />
                                            <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.ContactUsPagePhone} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className={`form-group ${errors[KeyWordLocalization.ContactUsPageEmail] && 'error error_group'}`}>
                                            <label className='form-label'>{i18n(KeyWordLocalization.ContactUsPageEmail)}</label>
                                            <input type="email" className="form-control" {...register(KeyWordLocalization.ContactUsPageEmail, Validators({
                                                required: true,
                                            }))} />
                                            <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.ContactUsPageEmail} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <label className="form-label">{i18n(KeyWordLocalization.ContactUsPageMessage)}</label>
                                    <textarea className="form-control" {...register(KeyWordLocalization.ContactUsPageMessage)} rows={4}></textarea>
                                </div>
                                <div className="form-check col-12 mb-3 ms-2">
                                    <input className="form-check-input" type="checkbox" {...register(KeyWordLocalization.ContactUsPageSuscribe, Validators({
                                    }))} />
                                    <label className="form-check-label">
                                        {i18n(KeyWordLocalization.ContactUsPageSuscribe)}
                                    </label>
                                </div>
                                <div className="form-check col-12 ms-2">
                                    <input className="form-check-input" type="checkbox" {...register(KeyWordLocalization.ContactUsPageSavePreferences)} />
                                    <label className="form-check-label">
                                        {i18n(KeyWordLocalization.ContactUsPageSavePreferences)}
                                    </label>
                                </div>
                                <div className="col-12">
                                    <a href="">{i18n(KeyWordLocalization.ContactUsPageTermsAndConditions)}</a>
                                </div>
                            </div>

                            <SendFormButtonComponent
                                text={i18n(KeyWordLocalization.ContactUsPageButton)}
                                type={ButtonType.MAIN}
                                isLoading={loading}
                                typeAction='submit'
                            />

                        </form>
                    </div>
                    <div className="col-md-5 col-lg-3 mt-3">
                        <ContactInformationComponent
                            title={i18n(KeyWordLocalization.ContactUsPageContactData)}
                            icon={<span className="material-symbols-outlined">support_agent</span>}
                            child={
                                <>
                                    {localization.code == "es" && <ContactPersonItemComponent person={adrianaData.person} phone2={adrianaData.cellphone2} />}
                                    {<ContactPersonItemComponent person={marlonData} />}
                                </>
                            }
                        />
                        <ContactInformationComponent
                            title={i18n(KeyWordLocalization.ContactUsPageFaceToFaceCare)}
                            icon={<span className="material-symbols-outlined">domain</span>}
                            child={
                                <>
                                    <div className='mb-2'>
                                        <b>{i18n(KeyWordLocalization.ContactUsPageAddress)}<br /></b>
                                    </div>
                                    <>{i18n(KeyWordLocalization.ContactUsPageCareer)}<br /></>
                                    <>{"Jardín de Galicia"}<br /></>
                                    <>{i18n(KeyWordLocalization.ContactUsPageHouse)}<br /></>
                                    <>{"Cali - Valle - Colombia"}<br /></>
                                </>
                            }
                        />
                        <ContactInformationComponent
                            title={i18n(KeyWordLocalization.ContactUsPageSchedule)}
                            icon={<span className="material-symbols-outlined">
                                schedule
                            </span>}
                            child={
                                <>
                                    <div className='mb-1'>
                                        <b>{i18n(KeyWordLocalization.ContactUsPageMondayToFriday)}<br /></b>
                                    </div>
                                    <div className="mb-1">
                                        8:00 am a 6:00 pm<br />
                                    </div>
                                    <div className='mb-1'>
                                        <b>{i18n(KeyWordLocalization.ContactUsPageSaturday)}<br /></b>
                                    </div>
                                    <>8:00 am a 12:00 pm<br /></>
                                </>
                            }
                        />
                    </div>
                </div>

            </div>

        </div>
    </>
}

export default ContactUsPage;