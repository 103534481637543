import { useParams } from 'react-router-dom';
import './DetailedPropertyPageStyles.scss';
import { FC, useContext, useEffect, useRef, useState } from "react";
import FriendlyUrls from '../../../seo/FriendlyUrls';
import CarouselPropertyComponent from './components/carouselProperty/CarouselPropertyComponent';
import PropertyEntity from '../../../../domain/entities/PropertyEntity';
import di from '../../../../dependencyInjection/DependencyInjection';
import GetPropertyByIdUseCase, { GetPropertyByIdUseCaseName } from '../../../../domain/use_cases/properties/GetPropertyByIdUseCase';
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent';
import NoResultsPropertiesCompontent from '../components/noResults/NoResultsPropertiesCompontent';
import ContactPropertyComponent from './components/contactProperty/ContactPropertyComponent';
import DetailsPropertyComponent from './components/detailsProperty/DetailsPropertyComponent';
import MapsClosePropertiesComponent from './components/mapsCloseProperties/MapsClosePropertiesComponent';
import SearchPropertiesUseCase, { SearchPropertiesUseCaseName } from '../../../../domain/use_cases/properties/SearchPropertiesUseCase';
import RelatedPropertiesComponent from './components/relatedProperties/RelatedPropertiesComponent';
import PreferencesContext from '../../../../domain/providers/preferences/PreferencesContext';
import PreferencesContextType from '../../../../domain/providers/preferences/PreferencesContextType';
import NeedLoanComponent from './components/needLoan/NeedLoanComponent';
import HeaderPropertyComponent from './components/headerProperty/HeaderPropertyComponent';
import HelpSearchPropertyComponent from './components/helpSearchProperty/HelpSearchPropertyComponent';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import DetailedPropertyMetaTagComponent from '../../../seo/metas/DetailedPropertyMetaTagComponent';
import KeyWordLocalization from '../../../providers/localization/KeyWordLocalization';

const DetailedPropertyPage: FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const propertyId = FriendlyUrls.getIdFromFriendlyUrl(id!);
    const [property, setProperty] = useState<PropertyEntity | null | undefined>(undefined);
    const [relatedProperties, setRelatedProperties] = useState<PropertyEntity[] | undefined>(undefined); //TODO create the component
    const { refScrollableDiv } = useContext(PreferencesContext) as PreferencesContextType;
    const contentBRef = useRef<HTMLDivElement>(null);
    const contentARef = useRef<HTMLDivElement>(null);

    const _loadProperty = async () => {
        setProperty(undefined);
        setRelatedProperties(undefined);
        const tempProperty = await di.get<GetPropertyByIdUseCase>(GetPropertyByIdUseCaseName).call(propertyId);
        setProperty(tempProperty ? tempProperty : null);
        if (tempProperty)
            _loadRelatedProperties(tempProperty)
    }

    const _loadRelatedProperties = async (tempProperty: PropertyEntity) => {
        let results = [];
        const relatedTempProperties = await di.get<SearchPropertiesUseCase>(SearchPropertiesUseCaseName).call({
            itemsPerPage: 20,
            page: 1,
            sortBy: "",
            typeOfBusinessId: tempProperty.businesses?.flatMap((b) => b.typeOfBusiness.id) || [],
            cityId: tempProperty.location?.neighborhood?.zone?.city?.id,
            neighborhoodIds: [],
            tagsId: [],
            zoneIds: [],
            typeOfPropertiesId: tempProperty.typeProperty?.id ? [tempProperty.typeProperty!.id] : [],
            word: "",
        });
        results = relatedTempProperties.data;
        if (results.length < 3) {
            const moreRelated = await di.get<SearchPropertiesUseCase>(SearchPropertiesUseCaseName).call({
                itemsPerPage: 20,
                page: 1,
                sortBy: "",
                typeOfBusinessId: tempProperty.businesses?.flatMap((b) => b.typeOfBusiness.id) || [],
                cityId: tempProperty.location?.neighborhood?.zone?.city?.id,
                neighborhoodIds: [],
                tagsId: [],
                zoneIds: [],
                typeOfPropertiesId: [],
                word: "",
            });
            results = moreRelated.data;
        }
        if (results.length < 3) {
            const moreRelated = await di.get<SearchPropertiesUseCase>(SearchPropertiesUseCaseName).call({
                itemsPerPage: 20,
                page: 1,
                sortBy: "",
                typeOfBusinessId: tempProperty.businesses?.flatMap((b) => b.typeOfBusiness.id) || [],
                neighborhoodIds: [],
                tagsId: [],
                zoneIds: [],
                typeOfPropertiesId: tempProperty.typeProperty?.id ? [tempProperty.typeProperty!.id] : [],
                word: "",
            });
            results = moreRelated.data;
        }
        if (results.length < 3) {
            const moreRelated = await di.get<SearchPropertiesUseCase>(SearchPropertiesUseCaseName).call({
                itemsPerPage: 20,
                page: 1,
                sortBy: "",
                typeOfBusinessId: tempProperty.businesses?.flatMap((b) => b.typeOfBusiness.id) || [],
                neighborhoodIds: [],
                tagsId: [],
                zoneIds: [],
                typeOfPropertiesId: [],
                word: "",
            });
            results = moreRelated.data;
        }
        if (results.length < 3) {
            const moreRelated = await di.get<SearchPropertiesUseCase>(SearchPropertiesUseCaseName).call({
                itemsPerPage: 20,
                page: 1,
                sortBy: "",
                typeOfBusinessId: [],
                neighborhoodIds: [],
                tagsId: [],
                zoneIds: [],
                typeOfPropertiesId: [],
                word: "",
            });
            results = moreRelated.data;
        }
        setRelatedProperties(results);
    }


    const handleScroll = () => {
        const scrollTop = refScrollableDiv?.current?.scrollTop ?? 0;
        const heightA = contentARef?.current?.offsetHeight ?? 0;
        const heightB = contentBRef?.current?.offsetHeight ?? 0;

        const limitMargin = heightA - heightB;
        if (scrollTop > limitMargin) contentBRef!.current!.style!.marginTop = (limitMargin ) + 'px';
        else contentBRef!.current!.style!.marginTop = (scrollTop + 20) + 'px';
    };


    useEffect(() => {
        const scrollableItem = refScrollableDiv?.current;
        scrollableItem?.addEventListener('scroll', handleScroll);
        return () => {
            scrollableItem?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        _loadProperty();
    }, [id])

    if (property === undefined) return <LoadingComponent showLogo={true} fullScreen />
    if (property === null) return <NoResultsPropertiesCompontent /> //TODO create the component

    return <div className="detailed_property_page">
        <div className="floating_contact_mobile">
            <a href="#contact_form">
                {i18n(KeyWordLocalization.DetailedPropertyPageContactUs)}
            </a>
        </div>
        <DetailedPropertyMetaTagComponent property={property} />
        <div className="container container_detailed_property">
            <div className="row">
                <div className="col-md-8 col-lg-9 col-xl-9 info_property_scroll" ref={contentARef}>
                    <div className="d-flex flex-column flex-md-column-reverse">
                        <CarouselPropertyComponent property={property} multimediaItems={property.multimedias} shortDescription={FriendlyUrls.getShortDescriptionOfProperty(property)} />
                        <HeaderPropertyComponent property={property} />
                    </div>
                    <DetailsPropertyComponent property={property} />
                    <MapsClosePropertiesComponent property={property} height={'400px'} relatedProperties={relatedProperties} />
                    <div className="d-flex flex-column flex-md-column-reverse">
                        <div className="col-12">
                            <NeedLoanComponent property={property} />
                        </div>
                        <div className="d-md-none" id='contact_form'>
                            <ContactPropertyComponent property={property} />
                        </div>
                        <aside>
                            <RelatedPropertiesComponent properties={relatedProperties} />
                        </aside>
                    </div>
                    <div className="col-12">
                        <HelpSearchPropertyComponent />
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 col-xl-3 px-md-0 contact_property d-none d-md-block"
                    style={{ maxHeight: contentARef?.current?.offsetHeight }}
                >
                    <div className="contact_property_static" ref={contentBRef}
                        style={{ maxHeight: (refScrollableDiv?.current?.offsetHeight ?? 0) - 30, marginTop: 20 }}
                    >
                        <ContactPropertyComponent property={property} />
                    </div>

                </div>
            </div>

        </div>
    </div >
}

export default DetailedPropertyPage;