import { injectable } from "inversify";
import CookiesRepository from "../../repositories/CookiesRepository";
import CookiesEntity from "../../entities/CookiesEntity";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";

interface Props {
    cookiesRepository: CookiesRepository;
    preferencesProvider: PreferencesProvider;
}

@injectable()
class UpdateCookiesUseCase {
    _cookiesRepository: CookiesRepository;
    _preferencesProvider: PreferencesProvider;

    constructor(_: Props) {
        this._cookiesRepository = _.cookiesRepository;
        this._preferencesProvider = _.preferencesProvider;
    }

    public call = async (cookies: CookiesEntity): Promise<void> => {
        const response = await this._cookiesRepository.updateCookies(cookies);
        this._preferencesProvider.Actions.setCookies(cookies);
        return response;
    }
}

export default UpdateCookiesUseCase;

export const UpdateCookiesUseCaseName = "UpdateCookiesUseCase";