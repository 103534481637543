import './HeaderComponentStyles.scss';
import { FC, useContext, useState } from "react";
import HeaderComponentProps from "./HeaderComponentProps";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import { routes } from "../../../routes/Routes";
import { Link, useNavigate } from "react-router-dom";
import { MdFavoriteBorder } from "react-icons/md";
import {
    Collapse,
    Navbar,
    Nav,
    NavItem,
} from "reactstrap";
import MastersContext from "../../../../domain/providers/master/MastersContext";
import MastersContextType from "../../../../domain/providers/master/MastersContextType";
import KeyWordLocalization from '../../../providers/localization/KeyWordLocalization';
import FriendlyUrls from '../../../seo/FriendlyUrls';
import LanguageSelectComponent from './Language/LanguageSelectComponent';
const HeaderComponent: FC<HeaderComponentProps> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [isOpen, setIsOpen] = useState(false);
    const { typesOfBusinesses, typeOfProperties } = useContext(MastersContext) as MastersContextType;
    const [openedDropdown, setOpenedDropdown] = useState<string>("");
    const navigate = useNavigate();

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const _toogleDropdown = (dropdown: string, openOnDoble: boolean, isLink: boolean) => {
        //if screen is mobile open, else go to link
        if (window.innerWidth < 768) {
            if (openedDropdown === dropdown && !openOnDoble) {
                setOpenedDropdown("");
            } else if (openedDropdown !== dropdown) {
                setOpenedDropdown(dropdown);
            } else if (isLink) {
                navigate(dropdown);
            }
        }
        else {
            if (isLink) navigate(dropdown);
        }
    }


    return <header>
        <Navbar id="header" className="header_component bg-transparent px-3 position-md-fixed w-100" expand="lg">
            <Link className="navbar-brand me-5" to={routes().home.relativePath}>
                <img src="/assets/logos/logo.png" title="LAC asesorias inmobiliarias" alt="LAC asesorias inmobiliarias" height="50" />{" "}
            </Link>

            <div className="d-flex align-items-center navbar_icons d-lg-none">
                <div className="nav-link me-1 me-md-2">
                    <LanguageSelectComponent />
                </div>
                <Link to={routes().favorites.relativePath} className="nav-link">
                    <div className='d-flex align-items-center'>
                        <MdFavoriteBorder size={20} color='var(--color-main)' />
                        <div className="ms-1 d-none d-md-block">
                            {i18n(KeyWordLocalization.HeaderComponentFavorites)}
                        </div>
                    </div>

                </Link>
                <button
                    className="navbar-toggler border-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={toggleNavbar}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>
            <Collapse navbar id="navbarNav" isOpen={isOpen}>
                <Nav className="ml-auto d-md-flex align-items-center" navbar>
                    <NavItem className="mx-3">
                        <div className={`dropdown_hover ${openedDropdown.startsWith(routes().properties.relativePath) && 'open'}`}>
                            <div className="dropdown_title" onClick={() => _toogleDropdown(routes().properties.relativePath, false, true)}>
                                <div className="nav-link">
                                    {i18n(KeyWordLocalization.HeaderComponentProperties)}
                                    <span className="text_reduced ms-1" style={{ fontSize: 10 }}>
                                        &#9660;
                                    </span>{" "}
                                </div>
                            </div>
                            <div className="dropdown_content">
                                {typesOfBusinesses.map((type, index) => <div className={`dropdown_hover ${openedDropdown.startsWith(routes().properties.relativePath + '/' + type.name) && 'open'}`} key={index}>
                                    <div className="dropdown_title" onClick={() => _toogleDropdown(FriendlyUrls.searchFriendlyUrl([type.name], []), true, true)}>
                                        <div className="nav-link text-capitalize">
                                            {i18n(KeyWordLocalization.HeaderComponent_ + type.name)}
                                            <span className="text_reduced ms-1" style={{ fontSize: 10 }}>
                                                &#9654;
                                            </span>{" "}
                                        </div>
                                    </div>
                                    <div className="dropdown_content right">
                                        {typeOfProperties.map((typeProperty, index) => (
                                            <Link
                                                className='nav-link text-capitalize'
                                                key=
                                                {index}
                                                to={FriendlyUrls.searchFriendlyUrl([type.name], [typeProperty.name])}
                                            >
                                                {i18n(typeProperty.name)}
                                            </Link>
                                        ))}
                                    </div>
                                </div>)}
                                {/* <Link to={routes().properties_map.relativePath} className="nav-link px-2">
                                    {i18n(KeyWordLocalization.HeaderComponentMap)}
                                </Link> */}
                            </div>
                        </div>
                    </NavItem>
                    {/* <NavItem className="mx-3">
                        <div className={`dropdown_hover ${openedDropdown.startsWith(routes().services.relativePath) && 'open'}`}>
                            <div className="dropdown_title" onClick={() => _toogleDropdown(routes().services.relativePath, false, false)}>
                                <div className="nav-link">
                                    {i18n(KeyWordLocalization.HeaderComponentServices)}
                                    <span className="text_reduced ms-1" style={{ fontSize: 10 }}>
                                        &#9660;
                                    </span>{" "}
                                </div>
                            </div>
                            <div className="dropdown_content">
                                <Link to={routes().services_sell.relativePath} className="nav-link">
                                    {i18n(KeyWordLocalization.HeaderComponentServicesSell)}
                                </Link>
                                <Link to={routes().services_buy.relativePath} className="nav-link">
                                    {i18n(KeyWordLocalization.HeaderComponentServicesBuy)}
                                </Link>
                                <Link to={routes().services_rent.relativePath} className="nav-link">
                                    {i18n(KeyWordLocalization.HeaderComponentServicesRent)}
                                </Link>
                                <Link to={routes().services_management.relativePath} className="nav-link">
                                    {i18n(KeyWordLocalization.HeaderComponentServicesAdministration)}
                                </Link>
                                <Link to={routes().services_real_state_advice.relativePath} className="nav-link">
                                    {i18n(KeyWordLocalization.HeaderComponentServicesRealStateAdvice)}
                                </Link>
                                <Link to={routes().services_investment.relativePath} className="nav-link">
                                    {i18n(KeyWordLocalization.HeaderComponentServicesInvestment)}
                                </Link>
                            </div>
                        </div>
                    </NavItem> */}

                    {/* <NavItem className="mx-3">
                        <Link className="nav-link" to={routes().aboutUs.relativePath}>
                            {i18n(KeyWordLocalization.HeaderComponentAboutUs)}
                        </Link>
                    </NavItem> */}
                    {/* <NavItem className="mx-3">
                        <div className="dropdown_hover">
                            <div className="dropdown_title" onClick={() => _toogleDropdown(routes().tools.relativePath, false, false)}>
                                <div className="nav-link">
                                    {i18n(KeyWordLocalization.HeaderComponentTools)}
                                    <span className="text_reduced ms-1" style={{ fontSize: 10 }}>
                                        &#9660;
                                    </span>{" "}
                                </div>
                            </div>
                            <div className="dropdown_content">
                                <Link className="nav-link" to={routes().tools_notarial_charges_calculator.relativePath}>
                                    {i18n(KeyWordLocalization.HeaderComponentToolsNotarialChargesCalculator)}
                                </Link>
                                <Link className="nav-link" to={routes().tools_credit_calculator.relativePath}>
                                    {i18n(KeyWordLocalization.HeaderComponentToolsCreditCalculator)}
                                </Link>
                                <Link className="nav-link" to={routes().tools_guides.relativePath}>
                                    {i18n(KeyWordLocalization.HeaderComponentToolsGuides)}
                                </Link>
                                <Link className="nav-link" to={routes().tools_add_my_payment.relativePath}>
                                    {i18n(KeyWordLocalization.HeaderComponentToolsAddRentPayment)}
                                </Link>
                            </div>
                        </div>
                    </NavItem> */}
                    <NavItem className="mx-3">
                        <Link className="nav-link" to={routes().contact.relativePath}>
                            {i18n(KeyWordLocalization.HeaderComponentContactUs)}
                        </Link>
                    </NavItem>
                    {/* <NavItem className="mx-3">
                        <Link className="nav-link" to={routes().blog.relativePath}>
                            {i18n(KeyWordLocalization.HeaderComponentBlog)}
                        </Link>
                    </NavItem> */}

                </Nav>
            </Collapse>
            <div className="align-items-center navbar_icons d-none d-lg-flex">
                <div className="nav-link">
                    <LanguageSelectComponent />
                </div>
                <Link to={routes().favorites.relativePath} className="nav-link">
                    <div className='d-flex align-items-center'>
                        <MdFavoriteBorder />
                        <div className="ms-3 d-none d-md-block">
                            {i18n(KeyWordLocalization.HeaderComponentFavorites)}
                        </div>
                    </div>

                </Link>
                <button
                    className="navbar-toggler border-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={toggleNavbar}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>

        </Navbar >
    </header>


}

export default HeaderComponent;