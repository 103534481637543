import { injectable } from "inversify";
import MastersTablesRepository from "../../repositories/MastersTablesRepository";
import MastersProvider from "../../providers/master/MastersProvider";
import di from "../../../dependencyInjection/DependencyInjection";
import GetContactInfoPreferencesUseCase, { GetContactInfoPreferencesUseCaseName } from "../contact/GetContactInfoPreferencesUseCase";
import GetCookiesUseCase, { GetCookiesUseCaseName } from "../coookies/GetCookiesUseCase";
import GetLikedPropertiesUseCase, { GetLikedPropertiesUseCaseName } from "../properties/GetLikedPropertiesUseCase";
import GetCurrentLanguageUseCase, { GetCurrentLanguageUseCaseName } from "../localization/GetCurrentLanguageUseCase";
import GetAllMastersTablesUseCase, { GetAllMastersTablesUseCaseName } from "../masters/GetAllMastersTablesUseCase";
import GetDefaultGridUseCase, { GetDefaultGridUseCaseName } from "../preferences/GetDefaultGridUseCase";
import GetAvailableLanguagesUseCase, { GetAvailableLanguagesUseCaseName } from "../localization/GetAvailableLanguagesUseCase";

interface Props {
    mastersTablesRepository: MastersTablesRepository;
    mastersProvider: MastersProvider;
}
@injectable()
export default class LoadUseCase {
    _mastersTablesRepository: MastersTablesRepository;
    _mastersProvider: MastersProvider;

    constructor(_props: Props) {
        this._mastersTablesRepository = _props.mastersTablesRepository;
        this._mastersProvider = _props.mastersProvider;
    }

    public call = async (): Promise<void> => {
        await di.get<GetCurrentLanguageUseCase>(GetCurrentLanguageUseCaseName).call(true);
        await Promise.all([
            di.get<GetDefaultGridUseCase>(GetDefaultGridUseCaseName).call(),
            di.get<GetContactInfoPreferencesUseCase>(GetContactInfoPreferencesUseCaseName).call(),
            di.get<GetLikedPropertiesUseCase>(GetLikedPropertiesUseCaseName).call(),
            di.get<GetCookiesUseCase>(GetCookiesUseCaseName).call(),
            di.get<GetAllMastersTablesUseCase>(GetAllMastersTablesUseCaseName).call(),
            di.get<GetAvailableLanguagesUseCase>(GetAvailableLanguagesUseCaseName).call(),
        ]);
    }
}

export const LoadUseCaseName = "LoadUseCase";