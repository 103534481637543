import TypeOfPropertyEntity from "../../../domain/entities/TypeOfPropertyEntity";

const fromJSON = (data: any): TypeOfPropertyEntity => {
    return {
        id: data.fake_id,
        name: data.name,
    }
}

export default {
    fromJSON,
}