import { FC, useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import PropertyEntity from "../../../domain/entities/PropertyEntity";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../providers/localization/KeyWordLocalization";
import CapitalizeFirstLetter from "../../pages/tools/capitalizeFirstLetter/capitalizeFirstLetter";
import CurrencyParse from "../../utils/CurrencyParse";

interface DetailedPropertyMetaTagComponentProps {
    property: PropertyEntity | undefined;
}

const DetailedPropertyMetaTagComponent: FC<DetailedPropertyMetaTagComponentProps> = ({ property }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;

    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const _seoTitle = (property: PropertyEntity | undefined) => {
        if (property == null) return setTitle(i18n(KeyWordLocalization.DetailedPropertyMetaTagComponentNotFoundTitle));
        let business: String = "";
        const businessNames: String[] = property.businesses!.map(business => CapitalizeFirstLetter(i18n(business.typeOfBusiness.name)));
        if (businessNames?.length === 1) {
            business = businessNames[0];
        } else {
            const lastIndex: number = businessNames.length - 1;
            business = businessNames?.slice(0, lastIndex).join(", ") + " | " + businessNames?.[lastIndex];
        }
        // DetailedPropertyMetaTagComponentTitle: "DetailedPropertyMetaTagComponentTitle", //typeProperty, business, zone, neighborhood, city
        const resume = i18n(KeyWordLocalization.DetailedPropertyMetaTagComponentTitle, {
            typeProperty: CapitalizeFirstLetter(i18n(property.typeProperty!.name)),
            business: business,
            // zone: CapitalizeFirstLetter(property.location!.neighborhood.zone!.name),
            neighborhood: CapitalizeFirstLetter(property.location?.neighborhood.name ?? ""),
            city: CapitalizeFirstLetter(property.location!.neighborhood.zone!.city!.name)
        });
        setTitle(resume);
    }

    const _seoDescription = (property: PropertyEntity | undefined) => {
        if (property == null) return setDescription(i18n(KeyWordLocalization.DetailedPropertyMetaTagComponentNotFoundSubtitle));
        const rooms = property.rooms == 0 ? "" : property.rooms > 1 ? i18n(KeyWordLocalization.SEOImagePropertyRoomsPlural, { rooms: property.rooms }) : i18n(KeyWordLocalization.SEOImagePropertyRooms, { rooms: property.rooms });
        const restrooms = property.restrooms == 0 ? "" : property.restrooms > 1 ? i18n(KeyWordLocalization.SEOImagePropertyrestroomsPlural, { restrooms: property.restrooms }) : i18n(KeyWordLocalization.SEOImagePropertyrestrooms, { restrooms: property.restrooms });
        const garages = property.garage == 0 ? "" : property.garage > 1 ? i18n(KeyWordLocalization.SEOImagePropertyGaragesPlural, { garages: property.garage }) : i18n(KeyWordLocalization.SEOImagePropertyGarages, { garages: property.garage });
        const prices = property.businesses!.map(business => `${i18n(business.typeOfBusiness.name)}: ${CurrencyParse.toCop(business.price)}`).join(", ");
        const stratum = i18n(KeyWordLocalization.SEOImagePropertyStratum, { stratum: property.stratum });

        const translation = i18n(KeyWordLocalization.DetailedPropertyMetaTagComponentSubtitle, {
            typeProperty: CapitalizeFirstLetter(i18n(property.typeProperty!.name)),
            business: CapitalizeFirstLetter(i18n(property.businesses![0].typeOfBusiness.name)),
            zone: CapitalizeFirstLetter(property.location!.neighborhood.zone!.name),
            neighborhood: CapitalizeFirstLetter(property.location?.neighborhood.name ?? ""),
            city: CapitalizeFirstLetter(property.location!.neighborhood.zone!.city!.name),
            rooms: rooms,
            restrooms: restrooms,
            garages: garages,
            area: property.area,
            stratum: stratum,
            price: prices,
        });
        setDescription(translation);
        return;
    }

    useEffect(() => {
        _seoTitle(property);
        _seoDescription(property);
    }, [property]);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <link rel="image_src" href="/assets/logos/logo.png" type="image/png" />
                <meta name="description" content={description} />
                <meta name="keywords" content={i18n(KeyWordLocalization.DetailedPropertyMetaTagComponentKeyWords, {
                    typeProperty: CapitalizeFirstLetter(i18n(property?.typeProperty?.name ?? "")),
                    business: property?.businesses?.map(business => CapitalizeFirstLetter(i18n(business.typeOfBusiness.name))).join(", "),
                    neighborhood: CapitalizeFirstLetter(property?.location?.neighborhood.name ?? ""),
                    city: CapitalizeFirstLetter(property?.location?.neighborhood.zone?.city?.name ?? ""),
                    zone: CapitalizeFirstLetter(property?.location?.neighborhood.zone?.name ?? ""),
                    area: property?.area,
                    garage: property?.garage,
                    rooms: property?.rooms,
                    stratum: property?.stratum
                })} />
                <link rel="canonical" href={window.location.pathname} />
                <meta name="robots" content="index, follow" />

            </Helmet>
        </>
    );
}

export default DetailedPropertyMetaTagComponent;