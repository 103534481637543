import { injectable } from "inversify";
import ExceptionEntity from "../../domain/entities/ExceptionEntity";
import KeyWordLocalization from "../../presentation/providers/localization/KeyWordLocalization";

@injectable()
class RestApi {
    public KEY_TOKEN = "token_api_key";
    public url = "";

    public getToken = () => window.localStorage.getItem(this.KEY_TOKEN) ?? "";

    public setToken = (token: string) => {
        window.localStorage.setItem(this.KEY_TOKEN, token);
    };

    public removeToken = () => window.localStorage.removeItem(this.KEY_TOKEN);

    public _getHeaders = () => {
        return {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': (document.querySelector('meta[name="csrf-token"]'))?.textContent ?? '',
            'Authorization': this.getToken(),
        };
    }

    public _parseError = async (response: Response): Promise<ExceptionEntity> => {
        try {
            const error = await response.json();
            return {
                code: 'ErrorHost' + error.key,
            }
        } catch (error) {
            const errorAsText = await response.text();
            const key: string = 'ErrorHost' + errorAsText;
            return {
                code: key in KeyWordLocalization ? (KeyWordLocalization as { [key: string]: string })[key] : KeyWordLocalization.UnknownError,
            }
        }
    }


    public get = (relativeUrl: string): Promise<any> => new Promise((resolve, reject) => {
        fetch(this.url + relativeUrl, {
            method: 'GET',
            // mode: 'no-cors',
            headers: this._getHeaders(),
        }).then(async response => {
            if (!response.ok || response.status >= 400) {
                const errorParsed = await this._parseError(response);
                return reject(errorParsed);
            }
            const ts = await response.text();
            try {
                const js = await JSON.parse(ts);
                return resolve(js);

            } catch (error) {
                return resolve(ts);
            }
        }).catch((error) => reject(error));
    });


    public post = (relativeUrl: string, body: any): Promise<any> => new Promise((resolve, reject) => {
        fetch(this.url + relativeUrl, {
            method: 'POST',
            headers: this._getHeaders(),
            body: JSON.stringify(body),
        }).then(async response => {
            if (!response.ok || response.status >= 400) {
                const errorParsed = await this._parseError(response);
                return reject(errorParsed);
            }
            const ts = await response.text();
            try {
                const js = await JSON.parse(ts);
                return resolve(js);

            } catch (error) {
                return resolve(ts);
            }
        }).catch((error) => reject(error));
    });

    public put = (relativeUrl: string, body: any): Promise<any> => new Promise((resolve, reject) => {
        fetch(this.url + relativeUrl, {
            method: 'PUT',
            headers: this._getHeaders(),
            body: JSON.stringify(body),
        }).then(async response => {
            if (!response.ok || response.status >= 400) {
                const errorParsed = await this._parseError(response);
                return reject(errorParsed);
            }
            const ts = await response.text();
            try {
                const js = await JSON.parse(ts);
                return resolve(js);

            } catch (error) {
                return resolve(ts);
            }
        }).catch((error) => reject(error));
    });

    public remove = (relativeUrl: string): Promise<any> => new Promise((resolve, reject) => {
        fetch(this.url + relativeUrl, {
            method: 'DELETE',
            headers: this._getHeaders(),
        }).then(async response => {
            if (!response.ok || response.status >= 400) {
                const errorParsed = await this._parseError(response);
                return reject(errorParsed);
            }
            const ts = await response.text();
            try {
                const js = await JSON.parse(ts);
                return resolve(js);

            } catch (error) {
                return resolve(ts);
            }
        }).catch((error) => reject(error));
    });
}
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default RestApi;