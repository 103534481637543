import LocalizationEntity from "../../../../../domain/entities/LocalizationEntity";
import LocalizationList from "./dictionaries/LocalizationList";

const GetCurrentLanguageApiImpl = (keyStorage: string): LocalizationEntity => {
    const languageCode = localStorage.getItem(keyStorage);
    if (!languageCode) {
        const browserLanguage = navigator.language.split('-')[0];
        const newLanguage = LocalizationList.find(l => l.code === browserLanguage);
        if (newLanguage) {
            document.documentElement.lang = newLanguage.code;
            return newLanguage as LocalizationEntity;
        }
        document.documentElement.lang = "es";
        return LocalizationList.find(l => l.code === 'es') as LocalizationEntity;
    }
    return LocalizationList.find(l => l.code === languageCode) as LocalizationEntity;
}

export default GetCurrentLanguageApiImpl;