import CityEntity from "../entities/CityEntity";
import NeighborhoodEntity from "../entities/NeighborhoodEntity";
import TagEntity from "../entities/TagEntity";
import TypeOfBusinessEntity from "../entities/TypeOfBusinessEntity";
import TypeOfPropertyEntity from "../entities/TypeOfPropertyEntity";
import ZoneEntity from "../entities/ZoneEntity";

export default interface MastersTablesRepository {
    getAll: () => Promise<{
        cities: CityEntity[];
        neighborhoods: NeighborhoodEntity[];
        zones: ZoneEntity[];
        typesOfBusinesses: TypeOfBusinessEntity[];
        typesOfProperty: TypeOfPropertyEntity[];
        tags: TagEntity[];
    }>;
    getCities: () => Promise<CityEntity[]>;
    getTypesOfBusinesses: () => Promise<TypeOfBusinessEntity[]>;
    getTypesOfProperty: () => Promise<TypeOfPropertyEntity[]>;
    getTags: () => Promise<TagEntity[]>;
}

export const MastersTablesRepositoryName = "MastersTablesRepository";