import { injectable } from "inversify";
import ContactRepository from "../../../domain/repositories/ContactRepository";
import { Either, right } from "fp-ts/lib/Either";
import ContactInfoEntity from "../../../domain/entities/ContactInfoEntity";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import Testing from "../../../presentation/utils/Testing";

const contactInfoFake: ContactInfoEntity = {
    name: 'John',
    lastname: 'Doe',
    email: 'joe.doe@mailinator.com',
    phone: '3234686680',
    dial: '+57',
}
@injectable()
class ContactRepositoryFake implements ContactRepository {
    store = async (contactInfo: ContactInfoEntity, subscribe: boolean): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    storeContactMeForProperty = async (contactInfo: ContactInfoEntity, subscribe: boolean, propertyId: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    getContactPreferences = async (): Promise<ContactInfoEntity | undefined> => {
        await Testing.sleeper(1000);
        return contactInfoFake;
    }
    storeContactPreferences = async (contactInfo: ContactInfoEntity): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }

}

export default ContactRepositoryFake;