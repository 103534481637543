import { injectable } from "inversify";
import SubscribeRepository from "../../../domain/repositories/SubscribeRepository";
import { Either, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import Testing from "../../../presentation/utils/Testing";

@injectable()
class SubscribeRepositoryFake implements SubscribeRepository {
    suscribe = async (email: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    sendUnsubscribeCode = async (email: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
    confirmUnsubscribe = async (email: string, code: string, reason: string): Promise<Either<ExceptionEntity, void>> => {
        await Testing.sleeper(1000);
        return right(undefined);
    }
}

export default SubscribeRepositoryFake;