import { FC } from "react";
import CardItemProps from "./CardItemProps";
import './CardItemStyles.scss';

const CardItem: FC<CardItemProps> = ({ name, value }) => {
    return <div className='d-flex flex-column align-items-center card_item'>
        <h3 className="d-flex flex-column-reverse align-items-center justify-content-center">
            <span className="value_text">{`${value}`} </span>
            <span className="mt-2 title_text">{name}</span>
        </h3>
    </div>
}

export default CardItem;