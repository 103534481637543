import { injectable } from "inversify";
import CookiesRepository from "../../../domain/repositories/CookiesRepository";
import CookiesEntity from "../../../domain/entities/CookiesEntity";
import Testing from "../../../presentation/utils/Testing";

const cookiesFake: CookiesEntity = {
    marketing: true,
    analytics: true,
    userInterface: true,
}

@injectable()
class CookiesRepositoryFake implements CookiesRepository {
    getCookies = async (): Promise<CookiesEntity> => {
        await Testing.sleeper(1000);
        return cookiesFake;
    }
    updateCookies = async (cookies: CookiesEntity): Promise<void> => {
        await Testing.sleeper(1000);
    }
    shouldShowCookiesBanner = async (): Promise<boolean> => {
        await Testing.sleeper(1000);
        return true;
    }

}

export default CookiesRepositoryFake;