import { FC, useContext } from "react";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import { Helmet } from "react-helmet-async";
import KeyWordLocalization from "../../providers/localization/KeyWordLocalization";

const TermsAndConditionsPageMetaTagComponent: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    return <Helmet>
        <title>{i18n(KeyWordLocalization.TermsAndConditionsPageMetaTagComponentTitle)}</title>
        <link rel="image_src" href="/assets/promotional/link_preview.jpg" type="image/png" />
        <meta name="description" content={i18n(KeyWordLocalization.TermsAndConditionsPageMetaTagComponentDescription)} />
        <meta name="keywords" content={i18n(KeyWordLocalization.TermsAndConditionsPageMetaTagComponentKeyWords)} />
        <link rel="canonical" href={window.location.pathname} />
        <meta name="robots" content="index, follow" />

    </Helmet>
}

export default TermsAndConditionsPageMetaTagComponent;