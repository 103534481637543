import es from './es.json';
import en from './en.json';
import LocalizationEntity from '../../../../../../domain/entities/LocalizationEntity';

const LocalizationList: LocalizationEntity[] = [
    {
        name: 'english',
        code: 'en',
        dictionary: en,
        image: 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png'
    },
    {
        name: 'español',
        code: 'es',
        dictionary: es,
        image: 'https://cdn.countryflags.com/thumbs/colombia/flag-round-250.png'
    }
];

export default LocalizationList;