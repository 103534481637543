import './LoadingComponent.scss';
import { FC, useContext } from 'react';
import LoadingComponentProps from './LoadingComponentProps';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../providers/localization/KeyWordLocalization';


const LoadingComponent: FC<LoadingComponentProps> = ({ showLogo = false, fullScreen = false }) => {
  const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
  return <div className={`loading_component ${fullScreen && 'full_screen'}`}>
    {showLogo && <img className='logo' src='/assets/logos/logo-circle.svg' alt='logo' />}
    <div className='loading' />
    <strong>{i18n(KeyWordLocalization.LoadingComponentLoading)} ... </strong>
  </div>

}

export default LoadingComponent;
