import { injectable } from "inversify";
import FirebaseRepositoryImpl from "./FirebaseRepositoryImpl";
import FirebaseRepositoryFake from "./FirebaseRepositoryFake";

@injectable()
class FirebaseRepositoryDev extends FirebaseRepositoryImpl {
    fakeRepository = new FirebaseRepositoryFake();

    navigatePage = (page: string) => this.fakeRepository.navigatePage(page);
}

export default FirebaseRepositoryDev;