import TagEntity from "../../../domain/entities/TagEntity";

const fromJSON = (data: any): TagEntity => {
    return {
        id: data.id.toString(),
        name: data.name,
        color: data.color,
    }
}

export default {
    fromJSON,
}