import { injectable } from "inversify";
import BlogRepository from "../../repositories/BlogRepository";
import LocalizationProvider from "../../providers/localization/LocalizationProvider";

interface props {
    blogRepository: BlogRepository;
    localizationProvider: LocalizationProvider;
}

@injectable()
export default class SearchBlogUseCase {
    _blogRepository: BlogRepository;
    _localizationProvider: LocalizationProvider;

    constructor(_props: props) {
        this._blogRepository = _props.blogRepository;
        this._localizationProvider = _props.localizationProvider;
    }

    public call = async (params: { title: string, page: number, itemsPerPage: number }) => {
        const currentLanguage = this._localizationProvider.Actions.localization.code;
        this._blogRepository.search(params, currentLanguage);
    }
}

export const SearchBlogUseCaseName = "SearchBlogUseCase";