import di from "../../../../../dependencyInjection/DependencyInjection";
import PropertyEntity from "../../../../../domain/entities/PropertyEntity";
import PropertyDto from "../../../../dto/impl/PropertyDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

const _getFromLocal = async (keyProperties: string): Promise<PropertyEntity[]> => {
    // get values saved from local storage
    try {
        const currentLikedProperties = localStorage.getItem(keyProperties);
        if (currentLikedProperties) {
            const properties: PropertyEntity[] = JSON.parse(currentLikedProperties);
            return properties;
        }
        return [];
    } catch (error) {
        return [];
    }
}

const _getLastCheck = async (keyProperties: string, keyLastCheck: string): Promise<PropertyEntity[]> => {
    try {
        const actuals = await _getFromLocal(keyProperties);
        const idsToCheck = actuals.map(p => p.id);

        const relativeUrl = "/property/available/check";
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, {
            ids: idsToCheck,
        });
        const parsedData = response.map((property: any) => PropertyDto.fromJSON(property));
        localStorage.setItem(keyProperties, JSON.stringify(parsedData));
        localStorage.setItem(keyLastCheck, Date.now().toString());
        return parsedData;
    } catch (error) {
        return [];
    }
}
const GetLikedPropertiesApiImpl = async (keyProperties: string): Promise<PropertyEntity[]> => {
    try {
        const lastCheckKey = `${keyProperties}_lastCheck`;
        const lastCheck = localStorage.getItem(lastCheckKey);

        //if last check was less than one hour ago, get from local storage
        //else get from api
        if (lastCheck && (Date.now() - parseInt(lastCheck) < 3600000)) {
            return _getFromLocal(keyProperties);
        } else {
            // get from api
            const properties = await _getLastCheck(keyProperties, lastCheckKey);
            return properties;
        }
    } catch (error) {
        return [];
    }

}

export default GetLikedPropertiesApiImpl;