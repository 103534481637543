import './SearcherBarComponentStyles.scss';
import { FC, useContext, useEffect } from "react";
import SearcherBarComponentProps from "./SearcherBarComponentProps";
import { useForm } from 'react-hook-form';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import AutoCompleteWithCheckComponent from '../form/autocompleteWithCheck/AutoCompleteWithCheckComponent';
import MastersContext from '../../../domain/providers/master/MastersContext';
import MastersContextType from '../../../domain/providers/master/MastersContextType';
import { useNavigate, useParams } from 'react-router-dom';
import { propertiesKeysFilters } from '../../pages/properties/components/sideFilter/SideFilterComponent';
import { routes } from '../../routes/Routes';
import KeyWordLocalization from '../../providers/localization/KeyWordLocalization';

const SearcherBarComponent: FC<SearcherBarComponentProps> = ({ onSubmit }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const formInfo = useForm();
    const { register, handleSubmit, setValue, watch, getValues, formState: { errors } } = formInfo;
    const { typesOfBusinesses, typeOfProperties } = useContext(MastersContext) as MastersContextType;
    const { businesses, types, city } = useParams();
    const navigate = useNavigate();
    let _timerTap: any;

    const onChange = () => {
        if (window.location.pathname.startsWith(routes().properties.relativePath)) {
            _handleOnSubmit();
        } else return;

    }

    const _handleOnSubmit = () => {
        clearTimeout(_timerTap);
        let typesTemp = getValues(propertiesKeysFilters.typeProperties);
        let businessesTemp = getValues(propertiesKeysFilters.typeBusinesses);
        const mappedBussineses = businessesTemp.map((business: any) => i18n(business.aditionalValue.name).replaceAll(" ", "_")).join('-').toLowerCase();
        let cityTemp = city;
        let redirectUrl = routes().properties.pathTo(
            mappedBussineses,
            typesTemp.map((type: any) => i18n(type.label).replaceAll(" ", "_")).join('-').toLocaleLowerCase(),
            cityTemp
        );
        if (window.location.pathname.startsWith(routes().properties.relativePath)) {
            //then on change resend and convert url params
            const queryParams = new URLSearchParams();
            if (getValues(propertiesKeysFilters.word)) queryParams.set(i18n(propertiesKeysFilters.word), getValues(propertiesKeysFilters.word));
            redirectUrl += `?${queryParams.toString()}`;
        } else if (getValues(propertiesKeysFilters.word)) {
            redirectUrl += `?${i18n(propertiesKeysFilters.word)}=${getValues(propertiesKeysFilters.word)}`;
        }
        navigate(redirectUrl);
    }

    const _loadDefaultBusinesses = () => {
        const businessesParam = businesses?.split('-');
        if (businessesParam && businessesParam.length > 0) {
            const typesAvailable = typesOfBusinesses.filter((type) => businessesParam.some((paramType) => paramType.replaceAll("_", " ").toLowerCase() == i18n(type.name).toLowerCase()));
            const parsedTypes = typesAvailable.map((type) => ({ label: i18n(type.name), id: type.id, aditionalValue: type }));
            setValue(propertiesKeysFilters.typeBusinesses, parsedTypes);
        } else {
            setValue(propertiesKeysFilters.typeBusinesses, []);
        }
    }

    const _loadDefaultWord = () => {
        //get word from query param
        const searchParams = new URLSearchParams(window.location.search);
        const word = searchParams.get(i18n(propertiesKeysFilters.word));
        setValue(propertiesKeysFilters.word, word);
    }

    const _loadDefaultTypes = () => {
        const typesOfParams = types?.split('-');
        if (typesOfParams && typesOfParams.length > 0) {
            const typesAvailable = typeOfProperties.filter((type) => typesOfParams.some((paramType) => paramType.replaceAll("_", " ").toLowerCase() == i18n(type.name).toLowerCase()));
            const parsedTypes = typesAvailable.map((type) => ({ label: i18n(type.name), id: type.id, aditionalValue: type }));
            setValue(propertiesKeysFilters.typeProperties, parsedTypes);
        } else {
            setValue(propertiesKeysFilters.typeProperties, []);
        }
    }

    const _handleSearchTypeBusiness = async (word: string) => typesOfBusinesses.filter((type) => i18n(type.name).toLowerCase().includes(word.toLowerCase())).map((type) => ({ label: i18n(type.name), id: type.id, aditionalValue: type }));

    const _handleSearchTypeProperty = async (word: string) => typeOfProperties.filter((type) => i18n(type.name).toLowerCase().includes(word.toLowerCase())).map((type) => ({ label: i18n(type.name), id: type.id, aditionalValue: type }));

    const _handleChangeTextSearch = () => {
        //add timing to call on change with the idea of gave space to search
        clearTimeout(_timerTap);
        _timerTap = setTimeout(() => onChange(), 500);
    }

    useEffect(() => {
        _loadDefaultBusinesses();
    }, [typesOfBusinesses, businesses]);

    useEffect(() => {
        _loadDefaultTypes();
    }, [typeOfProperties, types]);

    useEffect(() => {
        _loadDefaultWord();
    }, []);

    return <div className="searcher_bar">
        <div className="w-100">
            <div className="container">
                <form onSubmit={handleSubmit(_handleOnSubmit)}>
                    <div className="row">
                        <div className="col-md-5">
                            <input type="text" className="form-control" placeholder={i18n(KeyWordLocalization.SideFilterWordSearcher)} {...register(propertiesKeysFilters.word, { onChange: _handleChangeTextSearch })} />
                        </div>
                        <div className="mt-3 mt-md-0 col-md-3">
                            <AutoCompleteWithCheckComponent keyName={propertiesKeysFilters.typeBusinesses} formInfo={formInfo} label= {i18n(KeyWordLocalization.TopSearcherBusinessType)} onSearch={_handleSearchTypeBusiness} labelInHint onChange={onChange} />
                        </div>
                        <div className="mt-3 mt-md-0 col-md-3">
                            <AutoCompleteWithCheckComponent keyName={propertiesKeysFilters.typeProperties} formInfo={formInfo} label={i18n(KeyWordLocalization.TopSearcherPropertyType)} onSearch={_handleSearchTypeProperty} labelInHint onChange={onChange} />
                        </div>
                        <div className="mt-3 mt-md-0 ps-md-0 pe-md-0 col-md-1">
                            <button type='submit' className="w-100 h-100 mbtn btn_orange">
                                {i18n(KeyWordLocalization.SideFilterWordSearch)}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
}

export default SearcherBarComponent;