import di from "../../../dependencyInjection/DependencyInjection";
import MultimediaEntity, { MultimediaEntityType } from "../../../domain/entities/MultimediaEntity";
import Constants, { ConstantsName } from "../../settings/constants/Constants";
import MultimediaInterestPointDto from "./MultimediaInterestPointDto";

const fromJSON = (json: any): MultimediaEntity => {
    return {
        url: di.get<Constants>(ConstantsName).HOST_IMAGE_URL + json['url'],
        type: json['type_multimedia']['name'] == "image" ? MultimediaEntityType.image : MultimediaEntityType.video,
        id: json['id'],
        interestsPoints: json['interestPoints'] ? (json['interestPoints'] as any[]).map(i => MultimediaInterestPointDto.fromJSON(i)) : [],
    };
}

export default {
    fromJSON,
}