import di from "../../../../../dependencyInjection/DependencyInjection";
import CityEntity from "../../../../../domain/entities/CityEntity";
import NeighborhoodEntity from "../../../../../domain/entities/NeighborhoodEntity";
import TagEntity from "../../../../../domain/entities/TagEntity";
import TypeOfBusinessEntity from "../../../../../domain/entities/TypeOfBusinessEntity";
import TypeOfPropertyEntity from "../../../../../domain/entities/TypeOfPropertyEntity";
import ZoneEntity from "../../../../../domain/entities/ZoneEntity";
import CityDto from "../../../../dto/impl/CityDto";
import TagDto from "../../../../dto/impl/TagDto";
import TypeOfBusinessDto from "../../../../dto/impl/TypeOfBusinessDto";
import TypeOfPropertyDto from "../../../../dto/impl/TypeOfPropertyDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";
import { tagsFake } from "../../MastersTablesRepositoryFake";

const GetAllMastesTablesApiImpl = async (): Promise<{ cities: CityEntity[]; neighborhoods: NeighborhoodEntity[]; zones: ZoneEntity[]; typesOfBusinesses: TypeOfBusinessEntity[]; typesOfProperty: TypeOfPropertyEntity[]; tags: TagEntity[] }> => {
    try {
        const relativeUrl = "/getSearcherData";
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        const cities = response.cities.map((item: any) => CityDto.fromJSON(item));
        const zones = cities.flatMap((city: CityEntity) => city.zones);
        const neighborhoods = zones.flatMap((zone: ZoneEntity) => zone.neighborhoods);
        const typesOfBusinesses = response.typesBusiness.map((item: any) => TypeOfBusinessDto.fromJSON(item));
        const typesOfProperty = response.typesProperties.map((item: any) => TypeOfPropertyDto.fromJSON(item));
        const tags = response.tags.map((item: any) => TagDto.fromJSON(item));

        return {
            cities,
            neighborhoods,
            zones,
            typesOfBusinesses,
            typesOfProperty,
            tags,
        }
    } catch (e) {
        return {
            cities: [],
            neighborhoods: [],
            zones: [],
            typesOfBusinesses: [],
            typesOfProperty: [],
            tags: [],
        };
    }
}

export default GetAllMastesTablesApiImpl;