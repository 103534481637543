const makeFirstLetterUpperCase = (str: string): string => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const makeTextCapitalized = (str: string): string => {
    if (!str) return str;
    return str.toLowerCase().split(' ').map(word => makeFirstLetterUpperCase(word)).join(' ');
}

const toYear = (data: string | number): string => {
    //pass from 1999 to 1.999
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default {
    makeFirstLetterUpperCase,
    makeTextCapitalized,
};