import React from "react";
import MastersContextType from "./MastersContextType";
import CityEntity from "../../entities/CityEntity";
import ZoneEntity from "../../entities/ZoneEntity";
import NeighborhoodEntity from "../../entities/NeighborhoodEntity";
import TypeOfBusinessEntity from "../../entities/TypeOfBusinessEntity";
import TypeOfPropertyEntity from "../../entities/TypeOfPropertyEntity";
import LocationEntity from "../../entities/LocationEntity";
import TagEntity from "../../entities/TagEntity";

export const defaultMastersContext: MastersContextType = {
    cities: [],
    neighborhoods: [],
    zones: [],
    typeOfProperties: [],
    typesOfBusinesses: [],
    tags: [],
    setCities: (cities: CityEntity[]) => {},
    setNeighborhoods: (neighborhoods: NeighborhoodEntity[]) => {},
    setZones: (zones: ZoneEntity[]) => {},
    setTypesOfProperties: (typeOfProperties: TypeOfPropertyEntity[]) => {},
    setTypesOfBusinesses: (typesOfBusinesses: TypeOfBusinessEntity[]) => {},
    setTags: (tags: TagEntity[]) => {},
};
const MastersContext = React.createContext<MastersContextType>(defaultMastersContext);

export default MastersContext;