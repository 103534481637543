import { injectable } from "inversify";
import PreferencesRepository from "../../../domain/repositories/PreferencesRepository";
import GetPreferenceSeeAsGridPropertyApiImpl from "./api/impl/GetPreferenceSeeAsGridPropertyApiImpl";
import SetPreferenceSeeAsGridPropertyApiImpl from "./api/impl/SetPreferenceSeeAsGridPropertyApiImpl";

@injectable()
class PreferencesRepositoryFake implements PreferencesRepository {
    keyGrid = "KEY_GRID_PROPERTIES";

    getSeeAsGrid = ():Promise<boolean> => GetPreferenceSeeAsGridPropertyApiImpl(this.keyGrid);
    setSeeAsGrid = (seeAsGrid: boolean):Promise<void> => SetPreferenceSeeAsGridPropertyApiImpl(seeAsGrid, this.keyGrid);
}

export default PreferencesRepositoryFake;