import { injectable } from "inversify";
import PropertyRepository from "../../repositories/PropertyRepository";

interface props {
    propertyRepository: PropertyRepository;
}

@injectable()
class OldSearchPropertiesUseCase {
    _propertyRepository: PropertyRepository;

    constructor(_: props) {
        this._propertyRepository = _.propertyRepository;
    }

    public call = async (params: {
        word: string, page: number, itemsPerPage: number,
        typeOfProperty?: string | undefined, typeOfBusiness?: string | undefined, isNew?: boolean | undefined,
        cityId?: string | undefined, zoneId?: string | undefined, neighborhoodId?: string | undefined,
        minPrice?: number | undefined, maxPrice?: number | undefined,
    }) => this._propertyRepository.oldSearchProperties(params);
}

export default OldSearchPropertiesUseCase;

export const OldSearchPropertiesUseCaseName = "OldSearchPropertiesUseCase";