import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../entities/ExceptionEntity";
import ContactInfoEntity from "../entities/ContactInfoEntity";

export default interface ContactRepository {
    store(contactInfo: ContactInfoEntity, subscribe: boolean): Promise<Either<ExceptionEntity, void>>;
    storeContactMeForProperty(contactInfo: ContactInfoEntity, subscribe: boolean, propertyId: string): Promise<Either<ExceptionEntity, void>>;
    getContactPreferences: () => Promise<ContactInfoEntity | undefined>;
    storeContactPreferences: (contactInfo: ContactInfoEntity) => Promise<Either<ExceptionEntity, void>>;
}

export const ContactRepositoryName = 'ContactRepository';