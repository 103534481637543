import { injectable } from "inversify";
import SubscribeRepository from "../../../domain/repositories/SubscribeRepository";
import SubscribeRepositoryFake from "./SubscribeRepositoryFake";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import SubscribeEmailApiImpl from "./api/impl/SubscribeEmailApiImpl";

@injectable()
class SubscribeRepositoryDev implements SubscribeRepository {
    fakeRepository = new SubscribeRepositoryFake();
    suscribe = (email: string): Promise<Either<ExceptionEntity, void>> => SubscribeEmailApiImpl(email);
    sendUnsubscribeCode = (email: string): Promise<Either<ExceptionEntity, void>> => this.fakeRepository.sendUnsubscribeCode(email);
    confirmUnsubscribe = (email: string, code: string, reason: string): Promise<Either<ExceptionEntity, void>> => this.fakeRepository.confirmUnsubscribe(email, code, reason);
}

export default SubscribeRepositoryDev;