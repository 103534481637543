import { FC, useContext, useEffect, useState } from "react";
import ContactFormPropertyComponentProps from "./ContactFormPropertyComponentProps";
import { Link, useParams } from "react-router-dom";
import FriendlyUrls from "../../../../../../seo/FriendlyUrls";
import { useForm } from 'react-hook-form';
import di from "../../../../../../../dependencyInjection/DependencyInjection";
import PostContactPropertyUseCase, { PostContactPropertyUseCaseName } from "../../../../../../../domain/use_cases/contact/PostContactPropertyUseCase";
import ContactInfoEntity from "../../../../../../../domain/entities/ContactInfoEntity";
import KeyWordLocalization from "../../../../../../providers/localization/KeyWordLocalization";
import GetContactInfoPreferencesUseCase, { GetContactInfoPreferencesUseCaseName } from "../../../../../../../domain/use_cases/contact/GetContactInfoPreferencesUseCase";
import LocalizationContext from "../../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../../domain/providers/localization/LocalizationContextType";
import PhoneInput, { Country } from 'react-phone-number-input'
import Validators from "../../../../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { isLeft } from "fp-ts/lib/Either";
import ModalsContext from "../../../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../../../domain/providers/modal/ModalsContextType";
import SendFormButtonComponent from "../../../../../../components/contactFormButton/SendFormButtonComponent";
import { ButtonType } from "../../../../../../components/contactFormButton/SendFormButtonComponentProps";

const ContactFormPropertyComponent: FC<ContactFormPropertyComponentProps> = () => {
    const { id } = useParams<{ id: string }>();
    const { register, handleSubmit, getValues, setValue, watch, reset, formState: { errors } } = useForm();

    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { openModalCustom } = useContext(ModalsContext) as ModalsContextType;

    const [loading, setLoading] = useState<boolean>(false);

    const propertyId = FriendlyUrls.getIdFromFriendlyUrl(id!);

    const _loadData = async () => {
        const defaultValues: ContactInfoEntity | undefined = await di.get<GetContactInfoPreferencesUseCase>(GetContactInfoPreferencesUseCaseName).call();
        if (defaultValues == undefined) return;
        setValue(KeyWordLocalization.ContactFormPropertyComponentName, defaultValues.name);
        setValue(KeyWordLocalization.ContactFormPropertyComponentLastname, defaultValues.lastname);
        setValue(KeyWordLocalization.ContactFormPropertyComponentEmail, defaultValues.email);
        setValue(KeyWordLocalization.ContactFormPropertyComponentPhone, defaultValues.dial + defaultValues.phone);
        setValue(KeyWordLocalization.ContactFormPropertyComponentSavePreference, true);
    }

    const _handleSubmit = async (data: any) => {
        if (loading) return;
        setLoading(true);
        const dividePhone = () => {
            const phoneWithDial = getValues(KeyWordLocalization.ContactFormPropertyComponentPhone);
            const phoneSplittedd = formatPhoneNumberIntl(phoneWithDial).split(' ');
            const dial = phoneSplittedd[0];
            const phone = phoneSplittedd.slice(1).join('');
            return { dial, phone };
        }
        const { dial, phone } = dividePhone();
        const contactInfo: ContactInfoEntity = {
            email: data[KeyWordLocalization.ContactFormPropertyComponentEmail],
            lastname: data[KeyWordLocalization.ContactFormPropertyComponentLastname],
            name: data[KeyWordLocalization.ContactFormPropertyComponentName],
            phone: phone,
            dial: dial,
        }
        const response = await di.get<PostContactPropertyUseCase>(PostContactPropertyUseCaseName).call(
            contactInfo,
            data[KeyWordLocalization.ContactFormPropertyComponentSubscribe],
            propertyId,
            data[KeyWordLocalization.ContactFormPropertyComponentSavePreference],
        );
        setLoading(false);
        if (isLeft(response)) {
            openModalCustom("sm", i18n(KeyWordLocalization.FinishModalErrorTitle), i18n(KeyWordLocalization.FinishModalErrorBody), {
                closeButton: true,
            });
        } else {
            openModalCustom("sm", i18n(KeyWordLocalization.FinishModalSuccessTitle), i18n(KeyWordLocalization.FinishModalSuccessBody), {
                closeButton: true,
            });
        }
    }

    const getDefaultCountry = Intl.DateTimeFormat().resolvedOptions().timeZone == "America/Bogota" ? "CO" : undefined;

    useEffect(() => {
        _loadData();
    }, []);

    return <div className="contact_form_property_component overflow-x-hidden">
        <form onSubmit={handleSubmit(_handleSubmit)}>
            <div className="row">
                <div className={`col-6 my-2 form-group ${errors[KeyWordLocalization.ContactFormPropertyComponentName] && 'error'}`}>
                    <input type="text" className="form-control"
                        placeholder={i18n(KeyWordLocalization.ContactFormPropertyComponentName)} {...register(KeyWordLocalization.ContactFormPropertyComponentName, Validators({
                            required: true,
                        }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.ContactFormPropertyComponentName} />
                </div>
                <div className={`col-6 my-2 form-group ${errors[KeyWordLocalization.ContactFormPropertyComponentLastname] && 'error'}`}>
                    <input type="text" className="form-control" placeholder={i18n(KeyWordLocalization.ContactFormPropertyComponentLastname)} {...register(KeyWordLocalization.ContactFormPropertyComponentLastname, Validators({
                        required: true,
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.ContactFormPropertyComponentLastname} />
                </div>
                <div className={`col-12 my-2 form-group ${errors[KeyWordLocalization.ContactFormPropertyComponentEmail] && 'error'}`}>
                    <input type="email" className="form-control" placeholder={i18n(KeyWordLocalization.ContactFormPropertyComponentEmail)} {...register(KeyWordLocalization.ContactFormPropertyComponentEmail, Validators({
                        email: true,
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.ContactFormPropertyComponentEmail} />
                </div>
                <div className={`col-12 my-2 form-group ${errors[KeyWordLocalization.ContactFormPropertyComponentPhone] && 'error error_group'}`}>
                    {/* <div className="form-control"> */}
                    <PhoneInput
                        defaultCountry={getDefaultCountry}
                        onChange={phone => setValue(KeyWordLocalization.ContactFormPropertyComponentPhone, phone)}
                        className="form-control"
                        value={watch(KeyWordLocalization.ContactFormPropertyComponentPhone)}
                        placeholder={i18n(KeyWordLocalization.ContactFormPropertyComponentPhone)} />
                    {/* </div> */}
                    <input type="hidden" className="form-control" {...register(KeyWordLocalization.ContactFormPropertyComponentPhone, Validators({
                        required: true,
                        minLength: 10,
                        maxLength: 15,
                        //pattern prefix more phone example +1 123456789
                        pattern: {
                            value: RegExp(/^[+][0-9]{1,3}[0-9]{10,14}$/),
                            message: i18n(KeyWordLocalization.ContactFormPropertyComponentPhoneNoIndex)
                        }
                    }))} />
                    <ErrorMessage as={"aside"} errors={errors} name={KeyWordLocalization.ContactFormPropertyComponentPhone} />
                </div>

                <div className="col-12 my-2 form-group">
                    <textarea className="form-control" placeholder={i18n(KeyWordLocalization.ContactFormPropertyComponentMessage)} {...register(KeyWordLocalization.ContactFormPropertyComponentMessage)} />
                </div>
                <div className="col-12 my-2 form-group">
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id={KeyWordLocalization.ContactFormPropertyComponentSubscribe} {...register(KeyWordLocalization.ContactFormPropertyComponentSubscribe)} />
                        <label className="form-check-label" htmlFor={KeyWordLocalization.ContactFormPropertyComponentSubscribe}>{i18n(KeyWordLocalization.ContactFormPropertyComponentSubscribe)}</label>
                    </div>
                </div>
                <div className="col-12 my-2 form-group">
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id={KeyWordLocalization.ContactFormPropertyComponentSavePreference} {...register(KeyWordLocalization.ContactFormPropertyComponentSavePreference)} />
                        <label className="form-check-label" htmlFor={KeyWordLocalization.ContactFormPropertyComponentSavePreference}>{i18n(KeyWordLocalization.ContactFormPropertyComponentSavePreference)}</label>
                    </div>
                </div>
                <div className="col-12 my-2 form-group">
                    <Link to="#" target="_blank">{i18n(KeyWordLocalization.ContactFormPropertyComponentAccept)}</Link>
                </div>
                <div className="col-12 my-2 form-group">
                    <SendFormButtonComponent
                        text={i18n(KeyWordLocalization.ContactFormPropertyComponentSubmit)}
                        type={ButtonType.MAIN}
                        isLoading={loading}
                        typeAction='submit'
                        width={'100%'}
                    />
                </div>
            </div>
        </form >
    </div >
}


export default ContactFormPropertyComponent;