import { injectable } from "inversify";
import MastersTablesRepository from "../../../domain/repositories/MastersTablesRepository";
import CityEntity from "../../../domain/entities/CityEntity";
import NeighborhoodEntity from "../../../domain/entities/NeighborhoodEntity";
import TypeOfBusinessEntity from "../../../domain/entities/TypeOfBusinessEntity";
import TypeOfPropertyEntity from "../../../domain/entities/TypeOfPropertyEntity";
import ZoneEntity from "../../../domain/entities/ZoneEntity";
import Testing from "../../../presentation/utils/Testing";
import TagEntity from "../../../domain/entities/TagEntity";

export const neighborhoodsFake: NeighborhoodEntity[] = [
    { name: 'neighborhood1-1', id: '1', zone_id: '1' },
    { name: 'neighborhood1-2', id: '2', zone_id: '1' },
    { name: 'neighborhood2-1', id: '3', zone_id: '2' },
    { name: 'neighborhood2-2', id: '4', zone_id: '2' },
    { name: 'neighborhoodA1-1', id: '5', zone_id: '3' },
    { name: 'neighborhoodA1-2', id: '6', zone_id: '3' },
    { name: 'neighborhoodA2-1', id: '7', zone_id: '4' },
    { name: 'neighborhoodA2-2', id: '8', zone_id: '4' },
];

export const tagsFake: TagEntity[] = [
    { name: 'Oportunidad de negocio', id: '1', color: '#9e8e9e' },
    { name: 'Inversion', id: '2', color: '#9e8dde' },

];

export const zonesFake: ZoneEntity[] = [
    { name: 'zone1', id: '1', neighborhoods: [neighborhoodsFake[0], neighborhoodsFake[1]] },
    { name: 'zone2', id: '2', neighborhoods: [neighborhoodsFake[2], neighborhoodsFake[3]] },
    { name: 'zoneA1', id: '3', neighborhoods: [neighborhoodsFake[4], neighborhoodsFake[5]] },
    { name: 'zoneA2', id: '4', neighborhoods: [neighborhoodsFake[6], neighborhoodsFake[7]] },
];

export const citiesFake: CityEntity[] = [
    { name: 'city1', id: '1', zones: [zonesFake[0], zonesFake[1]], neighborhoods: [neighborhoodsFake[0], neighborhoodsFake[1], neighborhoodsFake[2], neighborhoodsFake[3]] },
    { name: 'city2', id: '2', zones: [zonesFake[2], zonesFake[3]], neighborhoods: [neighborhoodsFake[4], neighborhoodsFake[5], neighborhoodsFake[6], neighborhoodsFake[7]] },
];

export const typesOfBusinessesFake: TypeOfBusinessEntity[] = [
    { name: 'sell', id: '1', },
    { name: 'rent', id: '2' },
    { name: 'alquile', id: '3' },
];

export const typesOfPropertiesFake: TypeOfPropertyEntity[] = [
    { name: 'house', id: '1',  },
    { name: 'apartment', id: '2' },
    { name: 'office', id: '3' },
];
@injectable()
class MastersTablesRepositoryFake implements MastersTablesRepository {
    getTags = async (): Promise<TagEntity[]> => {
        Testing.sleeper(1000);
        return tagsFake;
    }
    getAll = async (): Promise<{ cities: CityEntity[]; neighborhoods: NeighborhoodEntity[]; zones: ZoneEntity[]; typesOfBusinesses: TypeOfBusinessEntity[]; typesOfProperty: TypeOfPropertyEntity[]; tags: TagEntity[]}> => {
        Testing.sleeper(1000);
        return {
            cities: citiesFake,
            neighborhoods: neighborhoodsFake,
            zones: zonesFake,
            typesOfBusinesses: typesOfBusinessesFake,
            typesOfProperty: typesOfPropertiesFake,
            tags: tagsFake,
        };
    }
    getCities = async (): Promise<CityEntity[]> => {
        Testing.sleeper(1000);
        return citiesFake;
    }
    getTypesOfBusinesses = async (): Promise<TypeOfBusinessEntity[]> => {
        Testing.sleeper(1000);
        return typesOfBusinessesFake;
    }
    getTypesOfProperty = async (): Promise<TypeOfPropertyEntity[]> => {
        Testing.sleeper(1000);
        return typesOfPropertiesFake;
    }

}

export default MastersTablesRepositoryFake;