import PropertyRepository from "../../repositories/PropertyRepository";
import PropertyEntity from "../../entities/PropertyEntity";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";
import { injectable } from "inversify";

interface props {
    propertyRepository: PropertyRepository;
    preferencesProvider: PreferencesProvider;
}

@injectable()
class GetLikedPropertiesUseCase {
    _propertyRepository: PropertyRepository;
    _preferencesProvider: PreferencesProvider;

    constructor(_: props) {
        this._propertyRepository = _.propertyRepository;
        this._preferencesProvider = _.preferencesProvider;
    }

    public call = async (): Promise<PropertyEntity[]> => {
        if(this._preferencesProvider.Actions.likedProperties.length > 0) return this._preferencesProvider.Actions.likedProperties;
        const properties = await this._propertyRepository.getLikedProperties();
        this._preferencesProvider.Actions.setLikedProperties(properties);
        return properties;
    }
}

export default GetLikedPropertiesUseCase;

export const GetLikedPropertiesUseCaseName = "GetLikedPropertiesUseCase";