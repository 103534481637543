const toCop = (value: number, minimumDigits: number = 0, maximumDigits:number = 0):string => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: minimumDigits, maximumFractionDigits: maximumDigits}).format(value).replace(',00', '').replace(/\s/g, '');
}

const copToNumber = (value: string):number => {
    return parseInt(value.replace('$', '').replace('.', '').replace(',', ''));
}

export default {
    toCop,
    copToNumber,
};