import { Either, isLeft } from "fp-ts/lib/Either";
import di from "../../../dependencyInjection/DependencyInjection";
import PropertyEntity from "../../entities/PropertyEntity";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";
import PropertyRepository from "../../repositories/PropertyRepository";
import GetLikedPropertiesUseCase, { GetLikedPropertiesUseCaseName } from "./GetLikedPropertiesUseCase";
import ExceptionEntity from "../../entities/ExceptionEntity";
import { injectable } from "inversify";

interface props {
    propertyRepository: PropertyRepository;
    preferencesProvider: PreferencesProvider;
}

@injectable()
class GetOutstandingAndRecentPropertiesUseCase {
    _propertyRepository: PropertyRepository;
    _preferencesProvider: PreferencesProvider;

    constructor(_: props) {
        this._propertyRepository = _.propertyRepository;
        this._preferencesProvider = _.preferencesProvider;
    }

    public call = async (): Promise<Either<ExceptionEntity, {
        outstanded: PropertyEntity[],
        recent: PropertyEntity[],
        mostLiked: PropertyEntity[],
    }>> => {
        const likedProperties = await di.get<GetLikedPropertiesUseCase>(GetLikedPropertiesUseCaseName).call();
        const response = await this._propertyRepository.getOutstandedAndRecentProperties();
        if (isLeft(response)) return response;
        response.right.outstanded.forEach((property: PropertyEntity) => property.liked = likedProperties.some((liked) => liked.id == property.id));
        response.right.recent.forEach((property: PropertyEntity) => property.liked = likedProperties.some((liked) => liked.id == property.id));
        response.right.mostLiked.forEach((property: PropertyEntity) => property.liked = likedProperties.some((liked) => liked.id == property.id));
        return response;
    }
}

export default GetOutstandingAndRecentPropertiesUseCase;

export const GetOutstandingAndRecentPropertiesUseCaseName = "GetOutstandingAndRecentPropertiesUseCase";