import { injectable } from "inversify";
import SubscribeRepository from "../../../domain/repositories/SubscribeRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import SubscribeEmailApiImpl from "./api/impl/SubscribeEmailApiImpl";
import SendUnsubscribeCodeApiImpl from "./api/impl/SendUnsubscribeCodeApiImpl";
import ConfirmUnsubscribeEmailApiImpl from "./api/impl/ConfirmUnsubscribeEmailApiImpl";

@injectable()
class SubscribeRepositoryImpl implements SubscribeRepository{
    suscribe = (email: string): Promise<Either<ExceptionEntity, void>> => SubscribeEmailApiImpl(email);
    sendUnsubscribeCode = (email: string): Promise<Either<ExceptionEntity, void>> => SendUnsubscribeCodeApiImpl(email);
    confirmUnsubscribe = (email: string, code: string, reason: string): Promise<Either<ExceptionEntity, void>> => ConfirmUnsubscribeEmailApiImpl(email, code, reason);
}

export default SubscribeRepositoryImpl;