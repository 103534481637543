import { injectable } from "inversify";
import MastersTablesRepository from "../../../domain/repositories/MastersTablesRepository";
import CityEntity from "../../../domain/entities/CityEntity";
import NeighborhoodEntity from "../../../domain/entities/NeighborhoodEntity";
import TypeOfBusinessEntity from "../../../domain/entities/TypeOfBusinessEntity";
import TypeOfPropertyEntity from "../../../domain/entities/TypeOfPropertyEntity";
import ZoneEntity from "../../../domain/entities/ZoneEntity";
import GetAllTypesOfBusinessApiImpl from "./api/impl/GetAllTypesOfBusinessApiImpl";
import GetAllTypesOfPropertiesApiImpl from "./api/impl/GetAllTypesOfPropertiesApiImpl";
import GetAllCitiesApiImpl from "./api/impl/GetAllCitiesApiImpl";
import GetAllMastesTablesApiImpl from "./api/impl/GetAllMastesTablesApiImpl";
import TagEntity from "../../../domain/entities/TagEntity";
import MastersTablesRepositoryFake from "./MastersTablesRepositoryFake";

@injectable()
class MastersTablesRepositoryDev implements MastersTablesRepository {
    fakeRepository:MastersTablesRepositoryFake = new MastersTablesRepositoryFake();
    getTags = () => this.fakeRepository.getTags();
    getAll = (): Promise<{ cities: CityEntity[]; neighborhoods: NeighborhoodEntity[]; zones: ZoneEntity[]; typesOfBusinesses: TypeOfBusinessEntity[]; typesOfProperty: TypeOfPropertyEntity[]; tags: TagEntity[]}> => GetAllMastesTablesApiImpl();
    getCities = (): Promise<CityEntity[]> => GetAllCitiesApiImpl();
    getTypesOfBusinesses = (): Promise<TypeOfBusinessEntity[]> => GetAllTypesOfBusinessApiImpl();
    getTypesOfProperty = (): Promise<TypeOfPropertyEntity[]> => GetAllTypesOfPropertiesApiImpl();

}

export default MastersTablesRepositoryDev;