import di from "../../../../../dependencyInjection/DependencyInjection";
import TagEntity from "../../../../../domain/entities/TagEntity";
import TagDto from "../../../../dto/impl/TagDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

//TODO implement
const GetAllTagsApiImpl = async (): Promise<TagEntity[]> => {
    try {
        const relativeUrl = "/api/tags";
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        return response.map((item: any) => TagDto.fromJSON(item));
    } catch (e) {
        return [];
    }
};

export default GetAllTagsApiImpl;