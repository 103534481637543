import di from "../../../../../dependencyInjection/DependencyInjection";
import LocationEntity from "../../../../../domain/entities/LocationEntity";
import LocationDto from "../../../../dto/impl/LocationDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

//TODO create on web back and connect
const SearchPropertiesWithMapApiImpl = async (params: { word: string; page: number; itemsPerPage: number; typeOfPropertiesId: string[]; typeOfBusinessId: string[]; isNew?: boolean | undefined; minPrice?: number | undefined; maxPrice?: number | undefined; }, paramsSearch: {
    latMax: number, latMin: number, longMax: number, longMin: number
}): Promise<LocationEntity[]> => {
    try {
        const body = {
            "searcher": params.word,
            "page": params.page,
            "itemsPerPage": params.itemsPerPage,
            "typeOfProperty": params.typeOfPropertiesId.length > 0 ? params.typeOfPropertiesId : undefined,
            "typeOfBusiness": params.typeOfBusinessId.length > 0 ? params.typeOfBusinessId : undefined,
            "isNew": params.isNew,
            "minPrice": params.minPrice,
            "maxPrice": params.maxPrice,
            "latMax": paramsSearch.latMax,
            "latMin": paramsSearch.latMin,
            "longMax": paramsSearch.longMax,
            "longMin": paramsSearch.longMin,
        };
        const relativeUrl = "/property/search-by-map";
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, body);
        const parsedData = response.map((location: any) => LocationDto.fromJSON(location));
        throw new Error("Method not implemented.");
        return parsedData;
    } catch (e) {
        return [];
    }
}

export default SearchPropertiesWithMapApiImpl;