const MarkAsSeenPropertyApiImpl = async (propertyId: string, keySave: string): Promise<void> => {
    try {
        const getSaved = localStorage.getItem(keySave);
        if (getSaved) {
            const saved = JSON.parse(getSaved);
            if (!saved.includes(propertyId)) {
                saved.push(propertyId);
                localStorage.setItem(keySave, JSON.stringify(saved));
            }
        } else {
            localStorage.setItem(keySave, JSON.stringify([propertyId]));
            return;
        }
    }
    catch {
        return;
    }
}

export default MarkAsSeenPropertyApiImpl;