import di from "../../../../../dependencyInjection/DependencyInjection";
import TypeOfBusinessEntity from "../../../../../domain/entities/TypeOfBusinessEntity";
import TypeOfBusinessDto from "../../../../dto/impl/TypeOfBusinessDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

const GetAllTypesOfBusinessApiImpl = async (): Promise<TypeOfBusinessEntity[]> => {
    try{
        const relativeUrl = "/api/typeBusiness";
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        return response.map((item: any) => TypeOfBusinessDto.fromJSON(item));
    }catch(e){
        return [];
    }
}

export default GetAllTypesOfBusinessApiImpl;