import di from "../../../../../dependencyInjection/DependencyInjection";
import TypeOfPropertyEntity from "../../../../../domain/entities/TypeOfPropertyEntity";
import TypeOfPropertyDtoImpl from "../../../../dto/impl/TypeOfPropertyDto";
import HostApi, { HostApiName } from "../../../../settings/HostApi";

const GetAllTypesOfPropertiesApiImpl = async (): Promise<TypeOfPropertyEntity[]> => {
    try{
        const relativeUrl = "/api/typeProperties";
        const response = await di.get<HostApi>(HostApiName).get(relativeUrl);
        return response.map((item: any) => TypeOfPropertyDtoImpl.fromJSON(item));
    }catch(e){
        return [];
    }
}

export default GetAllTypesOfPropertiesApiImpl;