import { Either, left, right } from "fp-ts/lib/Either";
import ExceptionEntity, { ExceptionEntityFromError } from "../../../../../domain/entities/ExceptionEntity";
import PropertyEntity from "../../../../../domain/entities/PropertyEntity";
import di from "../../../../../dependencyInjection/DependencyInjection";
import HostApi, { HostApiName } from "../../../../settings/HostApi";
import IsPropertyLikedApiImpl from "./IsPropertyLikedApiImpl";

const _saveLocal = (key: string, value: any) => {
    //get values saved from local storage, push new value to array and save it again
    const currentLikedProperties = localStorage.getItem(key);
    if (currentLikedProperties) {
        const parsed = JSON.parse(currentLikedProperties);
        parsed.push(value);
        localStorage
            .setItem(key, JSON.stringify(parsed));
    } else {
        localStorage
            .setItem(key, JSON.stringify([value]));
    }
}

const _saveHistorical = (key: string, id: string) => {
    //get values saved from local storage, push new value to array and save it again
    const currentLikedProperties = localStorage.getItem(key);
    if (currentLikedProperties) {
        const parsed = JSON.parse(currentLikedProperties);
        parsed.push(id);
        localStorage
            .setItem(key, JSON.stringify(parsed));
    } else {
        localStorage
            .setItem(key, JSON.stringify([id]));
    }
}

const _isLikedAnyTime = (key: string, id: string): boolean => {
    const currentLikedProperties = localStorage.getItem(key);
    if (currentLikedProperties) {
        const parsed = JSON.parse(currentLikedProperties);
        return parsed.includes(id);
    }
    return false;
}

//TODO: create back and connect
const _sendToServer = async (property: PropertyEntity): Promise<Either<ExceptionEntity, void>> => {
    try {
        const relativeUrl = `/property/likeProperty/${property.id}`;
        const response = await di.get<HostApi>(HostApiName).post(relativeUrl, {});
        return response;
    } catch (error) {
        return left(ExceptionEntityFromError(error));
    }
}


const LikePropertyApiImpl = async (property: PropertyEntity, keySaveLocal: string, keySaveAnyTime: string): Promise<Either<ExceptionEntity, void>> => {
    try {
        const isLiked = await IsPropertyLikedApiImpl(property.id, keySaveLocal); //TODO CHANGE FOR LIKE
        if(isLiked) return right(undefined);
        _saveLocal(keySaveLocal, property);
        const _likeAnyTime = _isLikedAnyTime(keySaveAnyTime, property.id);
        if (_likeAnyTime) return right(undefined);
        _saveHistorical(keySaveAnyTime, property.id);
        return await _sendToServer(property);
    } catch (e) {
        return left(ExceptionEntityFromError(e));
    }
}

export default LikePropertyApiImpl;