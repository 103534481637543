import { injectable } from "inversify";
import PreferencesProvider from "../../providers/preferences/PreferencesProvider";
import PreferencesRepository from "../../repositories/PreferencesRepository";

interface props {
    preferencesProvider: PreferencesProvider,
    preferencesRepository: PreferencesRepository,
}

@injectable()
export default class SetDefaultGridUseCase {
    _preferencesProvider: PreferencesProvider;
    _preferencesRepository: PreferencesRepository;

    constructor(props: props) {
        this._preferencesProvider = props.preferencesProvider;
        this._preferencesRepository = props.preferencesRepository;
    }

    public call = async (asGreed: boolean): Promise<void> => {
        this._preferencesProvider.Actions.setPropertiesAsGreed(asGreed);
        this._preferencesRepository.setSeeAsGrid(asGreed);
    }
}

export const SetDefaultGridUseCaseName = "SetDefaultGridUseCase";
