import './SendFormButtonComponentStyles.scss';
import { FC } from "react";
import SendFormButtonComponent, { ButtonType } from "./SendFormButtonComponentProps";
import Spinner from 'react-bootstrap/Spinner';

const ContactFormButtonComponent: FC<SendFormButtonComponent> = ({ onClick, text, disabled = false,  icon, onClickIcon, colorLine, type = ButtonType.MAIN, height = 'auto', width = 'auto', isLoading, typeAction = "button" }) => {
    return <button type={typeAction} style={{width, height}} className={`button_component hover ${disabled && 'disabled'} ${type}`} onClick={() => { if (onClickIcon == undefined) onClick?.() }}>
        <div className='w-100' style={{ display: "flex" }}>
            <div className="icon_container" onClick={onClickIcon}>
                {isLoading ? <Spinner style={{ height: "20px", width: "20px" }} /> : icon}
            </div>
            <div className='text' onClick={() => { if (onClickIcon != undefined) onClick?.() }}>
                <div className='container_text'>
                    {text}
                </div>
                <div className="color_line" style={{ background: colorLine }}></div>
            </div>
        </div>
    </button>
}

export default ContactFormButtonComponent;