import { useNavigate } from 'react-router-dom';
import './PaginatorComponentStyles.scss';
import { FC, useContext, useState } from "react";
import { propertiesKeysFilters } from '../../pages/properties/components/sideFilter/SideFilterComponent';
import PaginatorComponentProps from './PaginatorComponentProps';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../providers/localization/KeyWordLocalization';

const PaginatorComponent: FC<PaginatorComponentProps> = ({ resultData }) => {
    const limitOfShow = window.innerWidth < 768 ? 3 : 5;
    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    const navigate = useNavigate();
    const changePage = (page: number) => {
        //get all url params and add page
        const currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set(propertiesKeysFilters.page, page.toString());
        navigate(window.location.pathname + '?' + currentUrlParams.toString(), { replace: true });
    }

    const _getArrayToShow = (): number[] => {
        if (resultData.totalPage <= limitOfShow) return Array(resultData.totalPage).fill(0).map((e, index) => index + 1);
        else if (resultData.page <= 3) return Array(5).fill(0).map((e, index) => index + 1);
        else if (resultData.page >= resultData.totalPage - (Math.floor(limitOfShow / 2))) return Array(5).fill(0).map((e, index) => resultData.totalPage - limitOfShow + index + 1);
        else return Array(limitOfShow).fill(0).map((e, index) => resultData.page - (Math.floor(limitOfShow / 2)) + index);
    }

    if (resultData == null || resultData.total == 0) return <></>

    return <div className="paginator_component">
        <div className="w-100 text-center">
            {i18n(KeyWordLocalization.PaginatorComponentShowing, {
                showing: resultData.data.length,
                total: resultData.total,
            })}
        </div>
        <div className="d-flex justify-content-center">
            {resultData.totalPage > 1 ? <div className="d-flex mb-5 mt-3">
                <div className={"o-option-paginator" + (resultData.page == 1 ? " disabled" : "")} onClick={() => changePage(resultData.page - 1)}><span className="material-symbols-outlined">chevron_left</span></div>
                {resultData.totalPage > limitOfShow && resultData.page > Math.ceil(limitOfShow / 2) ? <><div className="o-option-paginator" onClick={() => changePage(1)}>1</div> <div className="o-option-paginator">...</div> </> : ''}
                {_getArrayToShow().map((e, i) => <div key={e} className={"o-option-paginator" + (resultData.page == e ? " active" : "")} onClick={() => changePage(e)}>{e}</div>)}
                {resultData.totalPage > limitOfShow && resultData.page < resultData.totalPage - Math.floor(limitOfShow / 2) ? <><div className="o-option-paginator">...</div> <div className="o-option-paginator" onClick={() => changePage(resultData.totalPage)}>{resultData.totalPage}</div></> : ''}
                <div className={"o-option-paginator" + (resultData.page == resultData.totalPage ? " disabled" : "")} onClick={() => changePage(resultData.page + 1)}><span className="material-symbols-outlined">chevron_right</span></div>
            </div> : ''}
        </div>
    </div>
}

export default PaginatorComponent;