import { injectable } from "inversify";
import BlogRepository from "../../repositories/BlogRepository";
import BlogEntity from "../../entities/BlogEntity";
import LocalizationProvider from "../../providers/localization/LocalizationProvider";

interface Props {
    blogRepository: BlogRepository;
    localizationProvider: LocalizationProvider;
}

@injectable()
export default class GetMostImportantBlogsUseCase {
    _blogRepository: BlogRepository;
    _localizationProvider: LocalizationProvider;
    
    constructor(_props: Props) {
        this._blogRepository = _props.blogRepository;
        this._localizationProvider = _props.localizationProvider;
    }

    public call = async (): Promise<{mostViewed: BlogEntity[], mostLiked: BlogEntity[]}> => {
        const currentLanguage = this._localizationProvider.Actions.localization.code;
        const response = await this._blogRepository.getMostImportantBlogs(currentLanguage);
        return response;
    }
}

export const GetMostImportantBlogsUseCaseName = "GetMostImportantBlogsUseCase";