const IsPropertySeenApiImpl = async (propertyId: string, keySave: string): Promise<boolean> => {
    try {
        const getSaved = localStorage.getItem(keySave);
        if (getSaved) {
            const saved = JSON.parse(getSaved);
            return saved.includes(propertyId);
        }
        return false;
    }
    catch {
        return false;
    }
}

export default IsPropertySeenApiImpl;