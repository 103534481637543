import "./DetailsPropertyComponentStyles.scss";
import { FC, useContext } from "react";
import DetailsPropertyComponentProps from "./DetailsPropertyComponentProps";
import CurrencyParse from "../../../../../utils/CurrencyParse";
import TypeOfBusinessEntity from "../../../../../../domain/entities/TypeOfBusinessEntity";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";
import LocalizationContext from "../../../../../../domain/providers/localization/LocalizationContext";
import KeyWordLocalization from "../../../../../providers/localization/KeyWordLocalization";
import MultimediaInterestPointEntity from "../../../../../../domain/entities/MultimediaInterestPointEntity";
import MultimediaEntity from "../../../../../../domain/entities/MultimediaEntity";
import { MdCheckCircleOutline } from "react-icons/md";

//TODO ADD I18N
const DetailsPropertyComponent: FC<DetailsPropertyComponentProps> = ({ property }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const _getAditionalInfo = (): string[] => {
        const aditionalInfo: string[] = [];
        property.multimedias.forEach((multimedia: MultimediaEntity) => {
            multimedia.interestsPoints.forEach((interestPoint: MultimediaInterestPointEntity) => {
                if (aditionalInfo.indexOf(interestPoint.interestPointName) === -1)
                    aditionalInfo.push(interestPoint.interestPointName);
            });
        });
        return aditionalInfo;
    }
    return <div className="details_property_component">
        <div className="row d-flex">
            {property.businesses?.map((business, index) => <div key={index} className={property.businesses!.length == 1 ? 'col-12' : 'col-xl-6'}>
                <div className={`h6 d-flex flex-column flex-md-row text-center text_bold justify-content-center business_container ${property.businesses!.length == 1 && 'alone'}`} style={{ color: business.typeOfBusiness.color, backgroundColor: business.typeOfBusiness.colorHex + "10" }}>
                    <h2 className="type_business me-2 h5 mb-0 text_bold">{i18n(KeyWordLocalization.SEOPrice, {
                        business: i18n(business.typeOfBusiness.name),
                    })}</h2>
                    <h3 className='price_container h5 text_bold'><strong>{CurrencyParse.toCop(business.price)}</strong> <span className='text_small_2'>COP</span></h3>
                </div>
            </div>)}
        </div>
        <div className="main_info mt-4">
            <h2 className="title_detailed">{i18n(KeyWordLocalization.DetailsPropertyComponentMainCharacteristics)}</h2>
            <div className="main_info_container row">
                <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3">
                    <h2><span className="title_info">
                        {i18n(KeyWordLocalization.DetailsPropertyComponentPropertyCode)}</span>
                        <span className="content_info">
                            {property.id}
                        </span></h2>
                </div>
                <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3">
                    <h2><span className="title_info">{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyNeighborhood)}&nbsp;</span>
                        <span className="content_info">{property.location?.neighborhood.name}&nbsp;</span> </h2>
                </div>
                <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3">
                    <h2><span className="title_info">{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyLocalization)}&nbsp;</span>
                        <span className="content_info">{property.location?.neighborhood?.zone?.name} de {property.location?.neighborhood?.zone?.city?.name}&nbsp;</span>
                    </h2>

                </div>
                {property.businesses?.map((business: {
                    typeOfBusiness: TypeOfBusinessEntity,
                    price: number,
                }) => <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3" key={business.typeOfBusiness.id}>
                        <h2>
                            <span className="title_info">
                                {i18n(business.typeOfBusiness.name)}&nbsp;</span>
                            <span className="content_info"> {CurrencyParse.toCop(business.price)}&nbsp;</span>
                        </h2>
                    </div>)}

                <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3">
                    <h2><span className="title_info">{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyArea)}&nbsp;</span>
                        <span className="content_info">{property.area} m<sup>2</sup></span>
                        </h2>
                </div>
                <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3">
                    <h2><span className="title_info">{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyState)}&nbsp;</span>
                        <span className="content_info">{i18n(property.newProperty ? KeyWordLocalization.new_property : KeyWordLocalization.used_property)}&nbsp;</span>
                        </h2>

                </div>
                {property.priceAdmin > 0 && <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3">
                    <h2><span className="title_info">{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyAdministration)}</span>
                        <span className="content_info">{CurrencyParse.toCop(property.priceAdmin)}&nbsp;</span></h2>
                </div>}
                <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3">
                    <h2 className="inverted"><span className="content_info">{property.rooms}&nbsp;</span>
                        <span className="title_info">{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyRooms)}&nbsp;</span></h2>
                </div>
                <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3">
                    <h2 className="inverted"><span className="content_info">{property.restrooms}&nbsp;</span>
                        <span className="title_info">{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyBathrooms)}&nbsp;</span>
                        </h2>
                </div>
                <div className="main_info_item my-2 my-md-3 col-6 col-md-4 col-lg-3">
                    <h2 className="inverted"><span className="content_info">{property.garage > 0 ? property.garage : "No"}&nbsp;</span>
                        <span className="title_info">{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyParking)}&nbsp;</span>
                        </h2>
                </div>
            </div>
        </div>
        <div className="description_container">
            {property.description}
        </div>

        <div className="aditional_container row my-4">
            <h2 className="title_detailed">{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyAditionalCharacteristics)}</h2>
            {property.typeKitchen &&
                <div className="aditional_item col-12 col-md-6 col-lg-4 col-xl-3">
                    <MdCheckCircleOutline color="var(--color-green)" size={20} />
                    <h3>{i18n(property.typeKitchen)}</h3>
                </div>}
            {property.buildYear && <div className="aditional_item col-12 col-md-6 col-lg-4 col-xl-3">
                <MdCheckCircleOutline color="var(--color-green)" size={20} />
                <h3>{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyBuildYear)} {property.buildYear}</h3>
            </div>}
            <div className="aditional_item col-12 col-md-6 col-lg-4 col-xl-3">
                <MdCheckCircleOutline color="var(--color-green)" size={20} />
                <h3>{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyStratum)} {property.stratum}</h3>
            </div>
            {property.floor && <div className="aditional_item col-12 col-md-6 col-lg-4 col-xl-3">
                <MdCheckCircleOutline color="var(--color-green)" size={20} />

                <h3>{i18n(KeyWordLocalization.DetailsPropertyComponentPropertyFloor)} {property.floor}</h3>
            </div>}
            {_getAditionalInfo().map((info: string) => <div className="aditional_item col-12 col-md-6 col-lg-4 col-xl-3" key={info}>
                <MdCheckCircleOutline color="var(--color-green)" size={20} />
                <h3>{i18n(info)}</h3>
            </div>)}
        </div>
    </div>
}

export default DetailsPropertyComponent;