/********************************************************************************
 * File Header - Dependency Injection Configuration
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: June 4, 2024
 *
 * Description:
 * This file contains all the dependency injection configuration for the application.
 * Repositories:
 *  fake is all faked data, dev is implemented data, 
 *  if the data is not implemented yet the repository call faked
 *  impl is for production
 ********************************************************************************/

import { Container } from "inversify";
import "reflect-metadata";
import UserProvider, { UserProviderName } from "../domain/providers/user/UserProvider";
import UserProviderImpl from "../presentation/providers/user/UserProviderImpl";
import LocalizationProvider, { LocalizationProviderName } from "../domain/providers/localization/LocalizationProvider";
import ModalsProvider, { ModalsProviderName } from "../domain/providers/modal/ModalsProvider";
import MastersProvider, { MastersProviderName } from "../domain/providers/master/MastersProvider";
import PreferencesProvider, { PreferencesProviderName } from "../domain/providers/preferences/PreferencesProvider";
import LocalizationProviderImpl from "../presentation/providers/localization/LocalizationProviderImpl";
import MastersProviderImpl from "../presentation/providers/masters/MastersProviderImpl";
import ModalsProviderImpl from "../presentation/providers/modals/ModalsProviderImpl";
import PreferencesProviderImpl from "../presentation/providers/preferences/PreferencesProviderImpl";
import BlogRepository, { BlogRepositoryName } from "../domain/repositories/BlogRepository";
import BlogRepositoryFake from "../data/repositories/blog/BlogRepositoryFake";
import BlogRepositoryDev from "../data/repositories/blog/BlogRepositoryDev";
import BlogRepositoryImpl from "../data/repositories/blog/BlogRepositoryImpl";
import GetBlogByIdUseCase, { GetBlogByIdUseCaseName } from "../domain/use_cases/blog/GetBlogByIdUseCase";
import GetMostImportantBlogsUseCase, { GetMostImportantBlogsUseCaseName } from "../domain/use_cases/blog/GetMostImportantBlogsUseCase";
import SearchBlogUseCase, { SearchBlogUseCaseName } from "../domain/use_cases/blog/SearchBlogUseCase";
import Constants, { ConstantsName } from "../data/settings/constants/Constants";
import ConstantsDev from "../data/settings/constants/ConstantsDev";
import ConstantsImpl from "../data/settings/constants/ConstantsImpl";
import ConstantsFake from "../data/settings/constants/ConstantsFake";
import HostApi, { HostApiName } from "../data/settings/HostApi";
import ContactRepository, { ContactRepositoryName } from "../domain/repositories/ContactRepository";
import ContactRepositoryDev from "../data/repositories/contact/ContactRepositoryDev";
import ContactRepositoryFake from "../data/repositories/contact/ContactRepositoryFake";
import ContactRepositoryImpl from "../data/repositories/contact/ContactRepositoryImpl";
import GetContactInfoPreferencesUseCase, { GetContactInfoPreferencesUseCaseName } from "../domain/use_cases/contact/GetContactInfoPreferencesUseCase";
import PostContactPropertyUseCase, { PostContactPropertyUseCaseName } from "../domain/use_cases/contact/PostContactPropertyUseCase";
import PostContactUseCase, { PostContactUseCaseName } from "../domain/use_cases/contact/PostContactUseCase";
import CookiesRepository, { CookiesRepositoryName } from "../domain/repositories/CookiesRepository";
import CookiesRepositoryFake from "../data/repositories/cookies/CookiesRepositoryFake";
import CookiesRepositoryDev from "../data/repositories/cookies/CookiesRepositoryDev";
import CookiesRepositoryImpl from "../data/repositories/cookies/CookiesRepositoryImpl";
import FirebaseRepository, { FirebaseRepositoryName } from "../domain/repositories/FirebaseRepository";
import FirebaseRepositoryFake from "../data/repositories/firebase/FirebaseRepositoryFake";
import FirebaseRepositoryDev from "../data/repositories/firebase/FirebaseRepositoryDev";
import FirebaseRepositoryImpl from "../data/repositories/firebase/FirebaseRepositoryImpl";
import GetCookiesUseCase, { GetCookiesUseCaseName } from "../domain/use_cases/coookies/GetCookiesUseCase";
import ShouldShowCookiesUseCase, { ShouldShowCookiesUseCaseName } from "../domain/use_cases/coookies/ShouldShowCookiesUseCase";
import UpdateCookiesUseCase, { UpdateCookiesUseCaseName } from "../domain/use_cases/coookies/UpdateCookiesUseCase";
import FireBaseNavigateUseCase, { FireBaseNavigateUseCaseName } from "../domain/use_cases/firebase/FireBaseNavigateUseCase";
import LoadUseCase, { LoadUseCaseName } from "../domain/use_cases/default/LoadUseCase";
import LocalizationRepository, { LocalizationRepositoryName } from "../domain/repositories/LocalizationRepository";
import LocalizationRepositoryFake from "../data/repositories/localization/LocalizationRepositoryFake";
import MastersTablesRepository, { MastersTablesRepositoryName } from "../domain/repositories/MastersTablesRepository";
import MastersTablesRepositoryFake from "../data/repositories/mastersTables/MastersTablesRepositoryFake";
import LocalizationRepositoryDev from "../data/repositories/localization/LocalizationRepositoryDev";
import MastersTablesRepositoryImpl from "../data/repositories/mastersTables/MastersTablesRepositoryImpl";
import MastersTablesRepositoryDev from "../data/repositories/mastersTables/MastersTablesRepositoryDev";
import GetAvailableLanguagesUseCase, { GetAvailableLanguagesUseCaseName } from "../domain/use_cases/localization/GetAvailableLanguagesUseCase";
import GetCurrentLanguageUseCase, { GetCurrentLanguageUseCaseName } from "../domain/use_cases/localization/GetCurrentLanguageUseCase";
import SetCurrentLanguageUseCase, { SetCurrentLanguageUseCaseName } from "../domain/use_cases/localization/SetCurrentLanguageUseCase";
import GetAllCitiesUseCase, { GetAllCitiesUseCaseName } from "../domain/use_cases/masters/GetAllCitiesUseCase";
import GetAllMastersTablesUseCase, { GetAllMastersTablesUseCaseName } from "../domain/use_cases/masters/GetAllMastersTablesUseCase";
import GetAllTypeOfBusinessesUseCase, { GetAllTypeOfBusinessesUseCaseName } from "../domain/use_cases/masters/GetAllTypeOfBusinessesUseCase";
import GetAllTypeOfPropertiesUseCase, { GetAllTypeOfPropertiesUseCaseName } from "../domain/use_cases/masters/GetAllTypeOfPropertiesUseCase";
import SearchNeighborhoodUseCase, { SearchNeighborhoodUseCaseName } from "../domain/use_cases/masters/SearchNeighborhoodUseCase";
import PropertyRepository, { PropertyRepositoryName } from "../domain/repositories/PropertyRepository";
import PropertyRepositoryFake from "../data/repositories/property/PropertyRepositoryFake";
import PropertyRepositoryDev from "../data/repositories/property/PropertyRepositoryDev";
import PropertyRepositoryImpl from "../data/repositories/property/PropertyRepositoryImpl";
import ApplyToRentPropertyUseCase, { ApplyToRentPropertyUseCaseName } from "../domain/use_cases/properties/ApplyToRentPropertyUseCase";
import GetPropertiesInRangeMapUseCase, { GetPropertiesInRangeMapUseCaseName } from "../domain/use_cases/properties/GetPropertiesInRangeMapUseCase";
import GetLikedPropertiesUseCase, { GetLikedPropertiesUseCaseName } from "../domain/use_cases/properties/GetLikedPropertiesUseCase";
import GetOutstandingAndRecentPropertiesUseCase, { GetOutstandingAndRecentPropertiesUseCaseName } from "../domain/use_cases/properties/GetOutstandingAndRecentPropertiesUseCase";
import GetPropertyByIdUseCase, { GetPropertyByIdUseCaseName } from "../domain/use_cases/properties/GetPropertyByIdUseCase";
import LikePropertyByIdUseCase, { LikePropertyByIdUseCaseName } from "../domain/use_cases/properties/LikePropertyByIdUseCase";
import OldSearchPropertiesUseCase, { OldSearchPropertiesUseCaseName } from "../domain/use_cases/properties/OldSearchPropertiesUseCase";
import SearchPropertiesUseCase, { SearchPropertiesUseCaseName } from "../domain/use_cases/properties/SearchPropertiesUseCase";
import UnlikePropertyByIdUseCase, { UnlikePropertyByIdUseCaseName } from "../domain/use_cases/properties/UnlikePropertyByIdUseCase";
import SubscribeRepository, { SubscribeRepositoryName } from "../domain/repositories/SubscribeRepository";
import SubscribeRepositoryFake from "../data/repositories/subscribe/SubscribeRepositoryFake";
import SubscribeRepositoryDev from "../data/repositories/subscribe/SubscribeRepositoryDev";
import SubscribeRepositoryImpl from "../data/repositories/subscribe/SubscribeRepositoryImpl";
import SubscribeToNewsUseCase, { SubscribeToNewsUseCaseName } from "../domain/use_cases/subscribe/SubscribeToNewsUseCase";
import ConfirmUnsubscribeUseCase, { ConfirmUnsubscribeUseCaseName } from "../domain/use_cases/subscribe/ConfirmUnsubscribeUseCase";
import SendUnsubscribeCodeUseCase, { SendUnsubscribeCodeUseCaseName } from "../domain/use_cases/subscribe/SendUnsubscribeCodeUseCase";
import PreferencesRepository, { PreferencesRepositoryName } from "../domain/repositories/PreferencesRepository";
import PreferencesRepositoryFake from "../data/repositories/preferences/PreferencesRepositoryFake";
import PreferencesRepositoryDev from "../data/repositories/preferences/PreferencesRepositoryDev";
import PreferencesRepositoryImpl from "../data/repositories/preferences/PreferencesRepositoryImpl";
import GetDefaultGridUseCase, { GetDefaultGridUseCaseName } from "../domain/use_cases/preferences/GetDefaultGridUseCase";
import SetDefaultGridUseCase, { SetDefaultGridUseCaseName } from "../domain/use_cases/preferences/SetDefaultGridUseCase";

enum MODE_DI { PRODUCTION, DEVELOPMENT, TEST, FAKE }

let mode = MODE_DI.PRODUCTION.toString();
const di = new Container();
//#region ------------------ CONSTANTS ------------------ //
if (mode === MODE_DI.FAKE.toString()) {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsFake);
} else if (mode === MODE_DI.DEVELOPMENT.toString()) {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsDev);
} else {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsImpl);
}

di.bind<HostApi>(HostApiName).to(HostApi).inSingletonScope();
//#endregion ------------------ CONSTANTS ------------------ //

//#region ------------------ REPOSITORIES ------------------ //
if (mode === MODE_DI.FAKE.toString()) {
    di.bind<BlogRepository>(BlogRepositoryName).to(BlogRepositoryFake).inSingletonScope();
    di.bind<ContactRepository>(ContactRepositoryName).to(ContactRepositoryFake).inSingletonScope();
    di.bind<CookiesRepository>(CookiesRepositoryName).to(CookiesRepositoryFake).inSingletonScope();
    di.bind<FirebaseRepository>(FirebaseRepositoryName).to(FirebaseRepositoryFake).inSingletonScope();
    di.bind<LocalizationRepository>(LocalizationRepositoryName).to(LocalizationRepositoryFake).inSingletonScope();
    di.bind<MastersTablesRepository>(MastersTablesRepositoryName).to(MastersTablesRepositoryFake).inSingletonScope();
    di.bind<PreferencesRepository>(PreferencesRepositoryName).to(PreferencesRepositoryFake).inSingletonScope();
    di.bind<PropertyRepository>(PropertyRepositoryName).to(PropertyRepositoryFake).inSingletonScope();
    di.bind<SubscribeRepository>(SubscribeRepositoryName).to(SubscribeRepositoryFake).inSingletonScope();
} else if (mode === MODE_DI.DEVELOPMENT.toString()) {
    di.bind<BlogRepository>(BlogRepositoryName).to(BlogRepositoryDev).inSingletonScope();
    di.bind<ContactRepository>(ContactRepositoryName).to(ContactRepositoryDev).inSingletonScope();
    di.bind<CookiesRepository>(CookiesRepositoryName).to(CookiesRepositoryDev).inSingletonScope();
    di.bind<FirebaseRepository>(FirebaseRepositoryName).to(FirebaseRepositoryDev).inSingletonScope();
    di.bind<LocalizationRepository>(LocalizationRepositoryName).to(LocalizationRepositoryDev).inSingletonScope();
    di.bind<MastersTablesRepository>(MastersTablesRepositoryName).to(MastersTablesRepositoryDev).inSingletonScope();
    di.bind<PreferencesRepository>(PreferencesRepositoryName).to(PreferencesRepositoryDev).inSingletonScope();
    di.bind<PropertyRepository>(PropertyRepositoryName).to(PropertyRepositoryDev).inSingletonScope();
    di.bind<SubscribeRepository>(SubscribeRepositoryName).to(SubscribeRepositoryDev).inSingletonScope();
} else {
    di.bind<BlogRepository>(BlogRepositoryName).to(BlogRepositoryImpl).inSingletonScope();
    di.bind<ContactRepository>(ContactRepositoryName).to(ContactRepositoryImpl).inSingletonScope();
    di.bind<CookiesRepository>(CookiesRepositoryName).to(CookiesRepositoryImpl).inSingletonScope();
    di.bind<FirebaseRepository>(FirebaseRepositoryName).to(FirebaseRepositoryImpl).inSingletonScope();
    di.bind<LocalizationRepository>(LocalizationRepositoryName).to(LocalizationRepositoryDev).inSingletonScope();
    di.bind<MastersTablesRepository>(MastersTablesRepositoryName).to(MastersTablesRepositoryImpl).inSingletonScope();
    di.bind<PreferencesRepository>(PreferencesRepositoryName).to(PreferencesRepositoryImpl).inSingletonScope();
    di.bind<PropertyRepository>(PropertyRepositoryName).to(PropertyRepositoryImpl).inSingletonScope();
    di.bind<SubscribeRepository>(SubscribeRepositoryName).to(SubscribeRepositoryImpl).inSingletonScope();
}
//#endregion ------------------ REPOSITORIES ------------------ //

//#region ------------------ PROVIDERS ------------------ //   
di.bind<LocalizationProvider>(LocalizationProviderName).toConstantValue(LocalizationProviderImpl);
di.bind<MastersProvider>(MastersProviderName).toConstantValue(MastersProviderImpl);
di.bind<ModalsProvider>(ModalsProviderName).toConstantValue(ModalsProviderImpl);
di.bind<PreferencesProvider>(PreferencesProviderName).toConstantValue(PreferencesProviderImpl);
di.bind<UserProvider>(UserProviderName).toConstantValue(UserProviderImpl);
//#endregion ------------------ PROVIDERS ------------------ //   


//#region ------------------ USE CASES ------------------//
//#region ------------------ Blog ------------------//
di.bind<GetBlogByIdUseCase>(GetBlogByIdUseCaseName).toDynamicValue((context) => {
    return new GetBlogByIdUseCase({
        blogRepository: context.container.get<BlogRepository>(BlogRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
di.bind<GetMostImportantBlogsUseCase>(GetMostImportantBlogsUseCaseName).toDynamicValue((context) => {
    return new GetMostImportantBlogsUseCase({
        blogRepository: context.container.get<BlogRepository>(BlogRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
di.bind<SearchBlogUseCase>(SearchBlogUseCaseName).toDynamicValue((context) => {
    return new SearchBlogUseCase({
        blogRepository: context.container.get<BlogRepository>(BlogRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Blog ------------------//
//#region ------------------ Contact ------------------//
di.bind<GetContactInfoPreferencesUseCase>(GetContactInfoPreferencesUseCaseName).toDynamicValue((context) => {
    return new GetContactInfoPreferencesUseCase({
        contactRepository: context.container.get<ContactRepository>(ContactRepositoryName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
di.bind<PostContactPropertyUseCase>(PostContactPropertyUseCaseName).toDynamicValue((context) => {
    return new PostContactPropertyUseCase({
        contactRepository: context.container.get<ContactRepository>(ContactRepositoryName),
        modalsProvider: context.container.get<ModalsProvider>(ModalsProviderName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
di.bind<PostContactUseCase>(PostContactUseCaseName).toDynamicValue((context) => {
    return new PostContactUseCase({
        contactRepository: context.container.get<ContactRepository>(ContactRepositoryName),
        modalsProvider: context.container.get<ModalsProvider>(ModalsProviderName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Contact ------------------//
//#region ------------------ Cookies ------------------//
di.bind<GetCookiesUseCase>(GetCookiesUseCaseName).toDynamicValue((context) => {
    return new GetCookiesUseCase({
        cookiesRepository: context.container.get<CookiesRepository>(CookiesRepositoryName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
di.bind<ShouldShowCookiesUseCase>(ShouldShowCookiesUseCaseName).toDynamicValue((context) => {
    return new ShouldShowCookiesUseCase({
        cookiesRepository: context.container.get<CookiesRepository>(CookiesRepositoryName)
    });
}).inSingletonScope();
di.bind<UpdateCookiesUseCase>(UpdateCookiesUseCaseName).toDynamicValue((context) => {
    return new UpdateCookiesUseCase({
        cookiesRepository: context.container.get<CookiesRepository>(CookiesRepositoryName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Cookies ------------------//
//#region ------------------ Default ------------------//
di.bind<LoadUseCase>(LoadUseCaseName).toDynamicValue((context) => {
    return new LoadUseCase({
        mastersTablesRepository: context.container.get<MastersTablesRepository>(MastersTablesRepositoryName),
        mastersProvider: context.container.get<MastersProvider>(MastersProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Default ------------------//
//#region ------------------ Firebase ------------------//
di.bind<FireBaseNavigateUseCase>(FireBaseNavigateUseCaseName).toDynamicValue((context) => {
    return new FireBaseNavigateUseCase({
        firebaseRepository: context.container.get<FirebaseRepository>(FirebaseRepositoryName)
    });
}).inSingletonScope();
//#endregion ------------------ Firebase ------------------//
//#region ------------------ Localization ------------------//
di.bind<GetAvailableLanguagesUseCase>(GetAvailableLanguagesUseCaseName).toDynamicValue((context) => {
    return new GetAvailableLanguagesUseCase({
        localizationRepository: context.container.get<LocalizationRepository>(LocalizationRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
di.bind<GetCurrentLanguageUseCase>(GetCurrentLanguageUseCaseName).toDynamicValue((context) => {
    return new GetCurrentLanguageUseCase({
        localizationRepository: context.container.get<LocalizationRepository>(LocalizationRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
di.bind<SetCurrentLanguageUseCase>(SetCurrentLanguageUseCaseName).toDynamicValue((context) => {
    return new SetCurrentLanguageUseCase({
        localizationRepository: context.container.get<LocalizationRepository>(LocalizationRepositoryName),
        localizationProvider: context.container.get<LocalizationProvider>(LocalizationProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Localization ------------------//
//#region ------------------ Masters ------------------//
di.bind<GetAllCitiesUseCase>(GetAllCitiesUseCaseName).toDynamicValue((context) => {
    return new GetAllCitiesUseCase({
        mastersTablesRepository: context.container.get<MastersTablesRepository>(MastersTablesRepositoryName),
        mastersProvider: context.container.get<MastersProvider>(MastersProviderName)
    });
}).inSingletonScope();
di.bind<GetAllMastersTablesUseCase>(GetAllMastersTablesUseCaseName).toDynamicValue((context) => {
    return new GetAllMastersTablesUseCase({
        mastersTablesRepository: context.container.get<MastersTablesRepository>(MastersTablesRepositoryName),
        mastersProvider: context.container.get<MastersProvider>(MastersProviderName)
    });
}).inSingletonScope();
di.bind<GetAllTypeOfBusinessesUseCase>(GetAllTypeOfBusinessesUseCaseName).toDynamicValue((context) => {
    return new GetAllTypeOfBusinessesUseCase({
        mastersTablesRepository: context.container.get<MastersTablesRepository>(MastersTablesRepositoryName),
        mastersProvider: context.container.get<MastersProvider>(MastersProviderName)
    });
}).inSingletonScope();
di.bind<GetAllTypeOfPropertiesUseCase>(GetAllTypeOfPropertiesUseCaseName).toDynamicValue((context) => {
    return new GetAllTypeOfBusinessesUseCase({
        mastersTablesRepository: context.container.get<MastersTablesRepository>(MastersTablesRepositoryName),
        mastersProvider: context.container.get<MastersProvider>(MastersProviderName)
    });
}).inSingletonScope();
di.bind<SearchNeighborhoodUseCase>(SearchNeighborhoodUseCaseName).toDynamicValue((context) => {
    return new SearchNeighborhoodUseCase();
}).inSingletonScope();
//#endregion ------------------ Masters ------------------//
//#region ------------------ Preferences ------------------//
di.bind<GetDefaultGridUseCase>(GetDefaultGridUseCaseName).toDynamicValue((context) => {
    return new GetDefaultGridUseCase({
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName),
        preferencesRepository: context.container.get<PreferencesRepository>(PreferencesRepositoryName)
    });
}).inSingletonScope();
di.bind<SetDefaultGridUseCase>(SetDefaultGridUseCaseName).toDynamicValue((context) => {
    return new SetDefaultGridUseCase({
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName),
        preferencesRepository: context.container.get<PreferencesRepository>(PreferencesRepositoryName),
    });
}).inSingletonScope();
//#endregion ------------------ Preferences ------------------//

//#region ------------------ Property ------------------//
di.bind<ApplyToRentPropertyUseCase>(ApplyToRentPropertyUseCaseName).toDynamicValue((context) => {
    return new ApplyToRentPropertyUseCase({
        propertyRepository: context.container.get<PropertyRepository>(PropertyRepositoryName)
    });
}).inSingletonScope();
di.bind<GetLikedPropertiesUseCase>(GetLikedPropertiesUseCaseName).toDynamicValue((context) => {
    return new GetLikedPropertiesUseCase({
        propertyRepository: context.container.get<PropertyRepository>(PropertyRepositoryName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
di.bind<GetOutstandingAndRecentPropertiesUseCase>(GetOutstandingAndRecentPropertiesUseCaseName).toDynamicValue((context) => {
    return new GetOutstandingAndRecentPropertiesUseCase({
        propertyRepository: context.container.get<PropertyRepository>(PropertyRepositoryName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
di.bind<GetPropertiesInRangeMapUseCase>(GetPropertiesInRangeMapUseCaseName).toDynamicValue((context) => {
    return new GetPropertiesInRangeMapUseCase({
        propertyRepository: context.container.get<PropertyRepository>(PropertyRepositoryName)
    });
}).inSingletonScope();
di.bind<GetPropertyByIdUseCase>(GetPropertyByIdUseCaseName).toDynamicValue((context) => {
    return new GetPropertyByIdUseCase({
        propertyRepository: context.container.get<PropertyRepository>(PropertyRepositoryName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
di.bind<LikePropertyByIdUseCase>(LikePropertyByIdUseCaseName).toDynamicValue((context) => {
    return new LikePropertyByIdUseCase({
        propertyRepository: context.container.get<PropertyRepository>(PropertyRepositoryName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
di.bind<OldSearchPropertiesUseCase>(OldSearchPropertiesUseCaseName).toDynamicValue((context) => {
    return new OldSearchPropertiesUseCase({
        propertyRepository: context.container.get<PropertyRepository>(PropertyRepositoryName)
    });
}).inSingletonScope();
di.bind<SearchPropertiesUseCase>(SearchPropertiesUseCaseName).toDynamicValue((context) => {
    return new SearchPropertiesUseCase({
        propertyRepository: context.container.get<PropertyRepository>(PropertyRepositoryName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName),
    });
}).inSingletonScope();
di.bind<UnlikePropertyByIdUseCase>(UnlikePropertyByIdUseCaseName).toDynamicValue((context) => {
    return new UnlikePropertyByIdUseCase({
        propertyRepository: context.container.get<PropertyRepository>(PropertyRepositoryName),
        preferencesProvider: context.container.get<PreferencesProvider>(PreferencesProviderName)
    });
}).inSingletonScope();
//#endregion ------------------ Property ------------------//
//#region ------------------ Subscribe ------------------//
di.bind<ConfirmUnsubscribeUseCase>(ConfirmUnsubscribeUseCaseName).toDynamicValue((context) => {
    return new ConfirmUnsubscribeUseCase({
        subscribeRepository: context.container.get<SubscribeRepository>(SubscribeRepositoryName),
    });
}).inSingletonScope();
di.bind<SendUnsubscribeCodeUseCase>(SendUnsubscribeCodeUseCaseName).toDynamicValue((context) => {
    return new SendUnsubscribeCodeUseCase({
        subscribeRepository: context.container.get<SubscribeRepository>(SubscribeRepositoryName),
    });
}).inSingletonScope();
di.bind<SubscribeToNewsUseCase>(SubscribeToNewsUseCaseName).toDynamicValue((context) => {
    return new SubscribeToNewsUseCase({
        subscribeRepository: context.container.get<SubscribeRepository>(SubscribeRepositoryName),
    });
}).inSingletonScope();
//#endregion ------------------ Subscribe ------------------//
//#endregion ------------------ USE CASES ------------------//

export default di;