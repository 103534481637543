import React from "react";
import LocalizationContextType from "./LocalizationContextType";
import LocalizationEntity from "../../entities/LocalizationEntity";
import LocalizationList from "../../../data/repositories/localization/api/impl/dictionaries/LocalizationList";

export const defaultLocalizationContext: LocalizationContextType = {
    localizations: [],
    localization: LocalizationList.find(l => l.code === 'es') as LocalizationEntity,
    i18n: (keyWord: string, values?: any) => keyWord,
    setLocalization: (localization: LocalizationEntity) => {},
    setLocalizations: (localization: LocalizationEntity[]) => {}

};

const LocalizationContext = React.createContext<LocalizationContextType>(defaultLocalizationContext);

export default LocalizationContext;