import { injectable } from "inversify";
import MastersProvider from "../../providers/master/MastersProvider";
import PropertyRepository from "../../repositories/PropertyRepository";
import LocationEntity from "../../entities/LocationEntity";

interface props {
    propertyRepository: PropertyRepository;
}

@injectable()
class GetPropertiesInRangeMapUseCase {
    _propertyRepository: PropertyRepository;

    constructor(_: props) {
        this._propertyRepository = _.propertyRepository;
    }

    public call = (paramsSearch: {
        word: string, page: number, itemsPerPage: number,
        typeOfPropertiesId: string[], typeOfBusinessId: string[], isNew?: boolean | undefined,
        minPrice?: number | undefined, maxPrice?: number | undefined,
    }, mapLimits: {
        latMax: number, latMin: number, longMax: number, longMin: number
    }): Promise<LocationEntity[]> => this._propertyRepository.getAllPropertiesLocations(paramsSearch, mapLimits);
}

export default GetPropertiesInRangeMapUseCase;

export const GetPropertiesInRangeMapUseCaseName = "GetPropertiesInRangeMapUseCase";