import { FC, useContext } from "react";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import { Helmet } from "react-helmet-async";
import KeyWordLocalization from "../../providers/localization/KeyWordLocalization";

const ContactUsPageMetaTagComponent: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    return <Helmet>
        <title>{i18n(KeyWordLocalization.ContactUsPageMetaTagComponentTitle)}</title>
        <link rel="image_src" href="/assets/promotional/link_preview.jpg" type="image/png" />
        <meta name="description" content={i18n(KeyWordLocalization.ContactUsPageMetaTagComponentDescription)} />
        <meta name="keywords" content={i18n(KeyWordLocalization.ContactUsPageMetaTagComponentKeyWords)} />
        <link rel="canonical" href={window.location.pathname} />
        <meta name="robots" content="index, follow" />
    </Helmet>
}

export default ContactUsPageMetaTagComponent;