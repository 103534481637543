import { FC, useContext } from "react";
import LocalizationContextType from "../../../../../domain/providers/localization/LocalizationContextType";
import LocalizationContext from "../../../../../domain/providers/localization/LocalizationContext";
import KeyWordLocalization from "../../../../providers/localization/KeyWordLocalization";
import StringUtils from "../../../../utils/StringUtils";
import { Dropdown } from "react-bootstrap";
import di from "../../../../../dependencyInjection/DependencyInjection";
import SetCurrentLanguageUseCase, { SetCurrentLanguageUseCaseName } from "../../../../../domain/use_cases/localization/SetCurrentLanguageUseCase";

const LanguageSelectComponent: FC<{}> = () => {
    const { localization, setLocalization, i18n, localizations } = useContext(LocalizationContext) as LocalizationContextType;

    const _handleChangeLocalization = (code: string) => {
        const findLo = localizations.find((item) => item.code === code);
        di.get<SetCurrentLanguageUseCase>(SetCurrentLanguageUseCaseName).call(findLo!);
    };

    return <>
        <div className="language_select_component">
            <Dropdown>
                <Dropdown.Toggle variant="no_line" id="dropdown-basic">
                    <img style={{ width: '1em', marginBottom: '3px' }} src={localization.image} /> {StringUtils.makeFirstLetterUpperCase(localization.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {localizations.map((item, index) => (
                        <Dropdown.Item key={index} onClick={() => _handleChangeLocalization(item.code)}>
                            <img style={{ width: '1em', marginRight: "8px", marginBottom: '3px' }} src={item.image} alt={item.name} />
                            {StringUtils.makeFirstLetterUpperCase(item.name)}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </>;
};

export default LanguageSelectComponent;