import { injectable } from "inversify";
import BlogRepository from "../../../domain/repositories/BlogRepository";
import BlogEntity from "../../../domain/entities/BlogEntity";
import PaginateResultEntity from "../../../domain/entities/PaginateResultEntity";
import GetBlogByIdApiImpl from "./api/GetBlogByIdApiImpl";
import GetMostImportantBlogsApiImpl from "./api/GetMostImportantBlogsApiImpl";
import SearchBlogApiImpl from "./api/SearchBlogApiImpl";

@injectable()
class BlogRepositoryImpl implements BlogRepository {
    getById = (id: string, language: string): Promise<BlogEntity | null> => GetBlogByIdApiImpl(id, language);
    getMostImportantBlogs = (language: string) => GetMostImportantBlogsApiImpl(language);
    search = (params: { title: string; page: number; itemsPerPage: number; }, language: string) => SearchBlogApiImpl(params, language);
}

export default BlogRepositoryImpl;